import { EscolaLMSContext } from "@escolalms/sdk/lib/react";

import React, { useContext, useMemo } from "react";

import { SchoolContext } from "components/SchoolContext";
import Select from "components/Select";

const SemestersSelect: React.FC = () => {
  const { semesters } = useContext(EscolaLMSContext);
  const { semesterId, setSemesterId } = useContext(SchoolContext);

  const semestersOptions = useMemo(() => {
    const semesterOptions = semesters?.list?.map((item) => ({
      label: `${item.name} (spec: ${item.speciality}) - ${
        item.type === "winter" ? "zimowy" : "letni"
      }`,
      value: String(item.id),
    }));

    if (semesterOptions?.length === 0) {
      return [
        {
          label: "Brak semestrów",
          value: "0",
        },
      ];
    }

    if (semesterOptions?.length === 1) {
      setSemesterId(Number(semesterOptions[0].value));
    }

    return semesterOptions;
  }, [semesters?.list]);

  return (
    <div
      data-testid="semesters-select"
      style={{
        width: "100%",
      }}
    >
      <Select
        disabled={semesters.list?.length === 0}
        sufix
        small
        variant="noBorder"
        id="semester"
        name="semester"
        onChange={(e) => setSemesterId(Number(e.target.value))}
        defaultValue={semesterId ? String(semesterId) : "0"}
        options={semestersOptions || []}
      />
    </div>
  );
};

export default SemestersSelect;
