import { styled } from "styled-components";

export const StyledFooter = styled.footer`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};

  img {
    max-width: 100%;
    height: auto;
    max-height: 50px;
  }

  #sidebar ~ &::before {
    content: "";
    display: block;
    width: 178px;
  }
`;
