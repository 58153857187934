import styled from "styled-components";
import { getStyleBasedOnTheme } from "theme/utils";

import { Button } from "components/Button";

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  padding: 12px;

  border: 1px solid ${({ theme }) => theme.colors.border};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 0;
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin: 0;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) =>
    getStyleBasedOnTheme(theme, {
      base: theme.colors.red,
      contrast: theme.colors.primaryButton,
    })};
  transition: background-color 0.3s ease-in-out;

  &:not(:disabled):hover {
    background-color: ${({ theme }) =>
      getStyleBasedOnTheme(theme, {
        base: theme.colors.error,
        contrast: theme.colors.primaryButton,
      })};
  }
`;
