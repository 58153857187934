import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";

import { FC, useContext, useEffect } from "react";

import { CardComponent } from "components/Card";
import Layout from "components/Layout";
import Loader, { LoaderContainer } from "components/Loader";
import { Stack } from "components/Stack";

import { CardContainer } from "./styles";

const TeamPage: FC = () => {
  const { fetchScheduleTutors, scheduleTutors } = useContext(EscolaLMSContext);

  useEffect(() => {
    fetchScheduleTutors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Kadra">
      <div data-testid="team-page">
        {scheduleTutors.loading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <CardContainer data-testid="card">
            {scheduleTutors.list?.map((user) => (
              <Stack key={user.tutor.id}>
                <CardComponent tutor={user} />
              </Stack>
            ))}
          </CardContainer>
        )}
      </div>
    </Layout>
  );
};

export default TeamPage;
