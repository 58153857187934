import { EscolaLMSContext } from "@escolalms/sdk/lib/react";

import React, { ChangeEvent, useContext, useEffect, useState } from "react";

import { AvatarImage } from "./AvatarImage";
import { RowContainer } from "./styles";

type Props = {
  name: string;
  avatar?: string;
};

const Avatar: React.FC<Props> = ({ name, avatar }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { updateAvatar } = useContext(EscolaLMSContext);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setSelectedFile(file);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      updateAvatar(selectedFile);
      setSelectedFile(null);
    }
  };

  useEffect(() => {
    handleUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  return (
    <RowContainer $alignItems="center" $gap={20} data-testid="avatar-wrapper">
      <AvatarImage size="md" name={name} avatar={avatar} />
      <label htmlFor="fileInput">
        Dodaj nowe zdjęcie
        <input
          type="file"
          id="fileInput"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </label>
    </RowContainer>
  );
};

export default Avatar;
