import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { Link, useNavigate } from "react-router-dom";

import React, { useContext } from "react";

import Icon from "components/Icon";
import routeRoutes from "components/Routes/routes";
import UserInfo from "components/UserInfo";

import { menuItems } from "consts";

import {
  Button,
  Container,
  MenuItem,
  MenuList,
  SettingsContainer,
} from "./styles";

type Props = {
  visible: boolean;
};

const MobileMenu: React.FC<Props> = ({ visible }) => {
  const navigate = useNavigate();
  const { user, logout } = useContext(EscolaLMSContext);

  return (
    <Container $visible={visible} data-testid="mobile-menu">
      <SettingsContainer>
        {user.value && (
          <UserInfo
            name={user.value.name}
            albumNumber={user?.value?.index_no?.[0] || ""}
            avatar={user.value.avatar}
          />
        )}
        <Button
          title="Wyloguj"
          onClick={() => logout().then(() => navigate(routeRoutes.login))}
        >
          <Icon name="logout" />
        </Button>
      </SettingsContainer>
      <MenuList>
        {menuItems.map((item, index) => (
          <MenuItem key={index}>
            <Link to={item.link}>{item.name}</Link>
          </MenuItem>
        ))}
      </MenuList>
    </Container>
  );
};

export default MobileMenu;
