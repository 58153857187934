import React from "react";

import Loader from "components/Loader";

import { ProjectsData } from "../../types";
import { ProjectsListItem } from "../ProjectsListItem";
import { Wrapper } from "./styles";

interface ProjectsListProps {
  projects: ProjectsData;
  onDeleteSuccess?: () => void;
  onDeleteError?: () => void;
}

export const ProjectsList: React.FC<ProjectsListProps> = ({
  projects,
  onDeleteSuccess,
  onDeleteError,
}) => (
  <Wrapper data-testid="projects-list">
    {projects.loading && !projects.data.length && <Loader />}
    {projects.data.map((file, i) => (
      <ProjectsListItem
        file={file}
        ordinalNumber={i + 1}
        onDeleteSuccess={onDeleteSuccess}
        onDeleteError={onDeleteError}
      />
    ))}
  </Wrapper>
);
