import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { removeProject } from "@escolalms/sdk/lib/services/project";
import { format } from "date-fns";

import React, { useCallback, useContext, useState } from "react";

import { DATETIME_FORMAT } from "consts/dates";

import { Card, StyledButton, Text, TextWrapper, Title } from "./styles";

interface Props {
  file: API.ProjectFile;
  ordinalNumber: number;
  onDeleteSuccess?: () => void;
  onDeleteError?: () => void;
}

export const ProjectsListItem: React.FC<Props> = ({
  file: { id, created_at },
  ordinalNumber,
  onDeleteSuccess,
  onDeleteError,
}) => {
  const { token, apiUrl } = useContext(EscolaLMSContext);

  const [isDeleting, setIsDeleting] = useState(false);

  const onFileDelete = useCallback(async () => {
    if (!token) return console.warn("noToken");

    try {
      setIsDeleting(true);
      await removeProject(apiUrl, token, id);
      onDeleteSuccess?.();
    } catch {
      onDeleteError?.();
    } finally {
      setIsDeleting(false);
    }
  }, [apiUrl, id, onDeleteError, onDeleteSuccess, token]);

  return (
    <Card data-testid={`projects-list-item-${ordinalNumber}`}>
      <TextWrapper>
        <Title>Plik {ordinalNumber}</Title>
        <Text>{format(new Date(created_at), DATETIME_FORMAT)}</Text>
      </TextWrapper>
      <StyledButton loading={isDeleting} onClick={onFileDelete} icon="bin" />
    </Card>
  );
};
