import { FC } from "react";

import { getInitials } from "utils";

import { AvatarContainer } from "./styles";

type Props = {
  name: string;
  avatar?: string;
  size?: "sm" | "md";
};

export const AvatarImage: FC<Props> = ({ name, avatar, size = "sm" }) => (
  <AvatarContainer size={size} data-testid="avatar-container">
    {avatar ? (
      <img src={avatar} alt="avatar" data-testid="avatar-image" />
    ) : (
      getInitials(name)
    )}
  </AvatarContainer>
);
