import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { isAfter, isBefore, isWithinInterval } from "date-fns";
import { useParams } from "react-router-dom";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  getFlatLessons,
  getFlatTopics,
  getLessonParentsIds,
  getPrevNextTopic,
} from "utils/course";

interface CourseAgendaData {
  firstBlockedTopicIndex: number;
  firstBlockedTopic: API.Topic | undefined;
  firstBlockedTopicLessonIndex: number;
  firstBlockedTopicLesson: API.Lesson | undefined;
  blockedTopicIndexInParentLesson: number;
  lockedTopicsIds: number[];
  lockedLessonsIds: number[];
}

interface CourseContext {
  flatLessons?: API.Lesson[];
  flatTopics?: API.Topic[];
  prevTopic?: API.Topic | null;
  currentTopic?: API.Topic;
  nextTopic?: API.Topic | null;
  currentLesson?: API.Lesson;
  currentCourseProgram?: API.CourseProgram;
  currentCourseProgress?: API.CourseProgressItem;
  isNextTopicButtonDisabled?: boolean;
  setIsNextTopicButtonDisabled?: (b: boolean) => void;
  completeCurrentTopic?: () => void;
  finishedTopicsIds?: number[];
  courseAgendaData?: CourseAgendaData;
  currentLessonParentsIds?: number[];
  isCourseFinished?: boolean;
  isCurrentLessonInactive?: boolean;
  isBeyondProgressDeadline?: boolean;
}

const Context = React.createContext<CourseContext>({});

export const useCourse = () => useContext(Context);

const DISABLE_NEXT_BUTTON_TYPES = [
  API.TopicType.H5P,
  API.TopicType.Audio,
  API.TopicType.Video,
  API.TopicType.Project,
  API.TopicType.GiftQuiz,
];

export const CourseProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { sendProgress, program, fetchProgram, progress, fetchProgress } =
    useContext(EscolaLMSContext);
  const [isNextTopicButtonDisabled, setIsNextTopicButtonDisabled] =
    useState(false);

  const { courseId, topicId } = useParams<{
    courseId: string;
    topicId: string;
  }>();

  const currentCourseProgram = useMemo(() => program.value, [program.value]);

  const flatLessons = useMemo(
    () => getFlatLessons(currentCourseProgram?.lessons ?? []),
    [currentCourseProgram?.lessons]
  );

  const flatTopics = useMemo(
    () => getFlatTopics(currentCourseProgram?.lessons ?? []),
    [currentCourseProgram?.lessons]
  );

  const findLessonById = useCallback(
    (id: number) => flatLessons.find((l) => l.id === id),
    [flatLessons]
  );

  const findTopicById = useCallback(
    (id: number) => flatTopics.find((t) => t.id === id),
    [flatTopics]
  );

  const prevTopic = useMemo(
    () => getPrevNextTopic(Number(topicId), flatTopics),
    [flatTopics, topicId]
  );

  const currentTopic = useMemo(
    () => findTopicById(Number(topicId)),
    [findTopicById, topicId]
  );

  const nextTopic = useMemo(
    () => getPrevNextTopic(Number(topicId), flatTopics, "next"),
    [flatTopics, topicId]
  );

  const currentLesson = useMemo(
    () => currentTopic && findLessonById(currentTopic?.lesson_id),
    [findLessonById, currentTopic]
  );

  const currentCourseProgress = useMemo(
    () =>
      (progress.value ?? []).find(
        ({ course }) => course.id === Number(courseId)
      ),
    [progress, courseId]
  );

  const completeCurrentTopic = useCallback(() => {
    if (currentCourseProgram?.id === undefined) return;
    if (currentTopic?.id === undefined) return;

    setIsNextTopicButtonDisabled(false);
    sendProgress(currentCourseProgram.id, [
      {
        topic_id: currentTopic.id,
        status: API.CourseProgressItemElementStatus.COMPLETE,
      },
    ]);
  }, [sendProgress, currentCourseProgram?.id, currentTopic?.id]);

  const finishedTopicsIds = useMemo(
    () =>
      (currentCourseProgress?.progress ?? []).reduce<number[]>(
        (acc, { status, topic_id }) =>
          status === API.CourseProgressItemElementStatus.COMPLETE
            ? [...acc, topic_id]
            : acc,
        []
      ),
    [currentCourseProgress]
  );

  const courseAgendaData: CourseAgendaData = useMemo(() => {
    const firstBlockedTopicIndex = flatTopics.findIndex(
      (t) => !t.can_skip && !finishedTopicsIds.includes(t.id)
    );
    const firstBlockedTopic: API.Topic | undefined =
      flatTopics[firstBlockedTopicIndex];

    const firstBlockedTopicLessonIndex = flatLessons.findIndex(
      (l) => l.id === firstBlockedTopic?.lesson_id
    );
    const firstBlockedTopicLesson: API.Lesson | undefined =
      flatLessons[firstBlockedTopicLessonIndex];

    const blockedTopicIndexInParentLesson =
      firstBlockedTopicLesson?.topics?.findIndex(
        (t) => t.id === firstBlockedTopic?.id
      ) ?? -1;

    const lockedTopicsIds = flatTopics.reduce<number[]>(
      (acc, { id }, index) =>
        index > firstBlockedTopicIndex ? [...acc, id] : acc,
      []
    );

    const lockedLessonsIds = flatLessons.reduce<number[]>(
      (acc, { id }, index) =>
        index > firstBlockedTopicLessonIndex ? [...acc, id] : acc,
      []
    );

    return {
      firstBlockedTopicIndex,
      firstBlockedTopic,
      firstBlockedTopicLessonIndex,
      firstBlockedTopicLesson,
      blockedTopicIndexInParentLesson,
      lockedTopicsIds,
      lockedLessonsIds,
    };
  }, [finishedTopicsIds, flatLessons, flatTopics]);

  const currentLessonParentsIds: number[] = useMemo(() => {
    if (!currentLesson) return [];

    return getLessonParentsIds(flatLessons, currentLesson);
  }, [currentLesson, flatLessons]);

  const isCurrentLessonInactive: boolean = useMemo(() => {
    const lessons: (API.Lesson | undefined)[] = [
      ...currentLessonParentsIds,
      currentLesson?.id,
    ].map((id) => flatLessons.find((l) => l.id === id));

    const today = new Date();

    return lessons.some((l) => {
      if (!l || !l?.active_from) return false;

      const dateFrom = new Date(l.active_from);

      if (l?.active_to) {
        const interval: Interval = {
          start: dateFrom,
          end: new Date(l.active_to),
        };

        return !isWithinInterval(today, interval);
      }

      return isBefore(today, dateFrom);
    });
  }, [currentLesson?.id, currentLessonParentsIds, flatLessons]);

  const isBeyondProgressDeadline = useMemo(() => {
    if (!currentCourseProgress?.deadline) return false;

    const today = new Date();
    const courseDeadline = new Date(currentCourseProgress.deadline);

    return isAfter(today, courseDeadline);
  }, [currentCourseProgress?.deadline]);

  useEffect(() => {
    if (!courseId) return;

    fetchProgram(Number(courseId));
    fetchProgress();
  }, [fetchProgress, fetchProgram, courseId]);

  // disable next button
  useEffect(() => {
    setIsNextTopicButtonDisabled(
      DISABLE_NEXT_BUTTON_TYPES.includes(
        currentTopic?.topicable_type as API.TopicType
      )
    );
  }, [currentTopic?.topicable_type]);

  const isCourseFinished = useMemo(
    () =>
      (currentCourseProgress?.progress ?? []).every(
        ({ status }) => status === API.CourseProgressItemElementStatus.COMPLETE
      ),
    [currentCourseProgress?.progress]
  );

  return (
    <Context.Provider
      value={{
        flatLessons,
        flatTopics,
        prevTopic,
        currentTopic,
        nextTopic,
        currentLesson,
        currentCourseProgress,
        currentCourseProgram,
        isNextTopicButtonDisabled,
        setIsNextTopicButtonDisabled,
        completeCurrentTopic,
        finishedTopicsIds,
        courseAgendaData,
        currentLessonParentsIds,
        isCourseFinished,
        isCurrentLessonInactive,
        isBeyondProgressDeadline,
      }}
    >
      {children}
    </Context.Provider>
  );
};
