import { Downloads } from "@escolalms/components/lib/components/molecules/Downloads/Downloads";
import { API } from "@escolalms/sdk/lib";
import styled from "styled-components";

import React from "react";

type Props = {
  resources: API.TopicResource[];
  subtitle: string;
};

const StyledDownloads = styled.div`
  margin-top: 100px;

  .lms-icon-title {
    .icon {
      display: none !important;
    }
  }

  .name,
  .download-btn {
    color: ${({ theme }) => theme.colors.secondary};
    padding: 0 !important;

    &:hover {
      font-weight: ${({ theme }) => theme.fontWeights.normal}!important;
      color: ${({ theme }) => theme.colors.primary}!important;
    }
  }
`;

const CourseDownloads: React.FC<Props> = ({ resources, subtitle }) => {
  const mappedResources = resources.map((item) => {
    return { href: item.url, fileName: item.name };
  });
  return (
    <StyledDownloads data-testid="course-downloads">
      <Downloads
        subtitle={subtitle}
        title="Załączniki lekcji"
        downloads={mappedResources}
      />
    </StyledDownloads>
  );
};

export default CourseDownloads;
