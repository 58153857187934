import { API } from "@escolalms/sdk/lib";
import { styled } from "styled-components";

import React, { useState } from "react";

import CollapseContent from "./Content";
import CollapseHeader from "./Header";

type Props = {
  subject: API.GroupSubject;
  showLabels?: boolean;
};

type ContainerProps = {
  $isOpen: boolean;
};

const Container = styled.div<ContainerProps>`
  border-radius: 3px;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-left: 3px solid ${({ theme }) => theme.colors.secondary};
  border-bottom: none;
  box-shadow: ${({ theme, $isOpen }) => $isOpen && theme.shadows.primary};
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
`;

const SubjectCollapse: React.FC<Props> = ({ subject, showLabels }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container data-testid="collapse" $isOpen={isOpen}>
      <CollapseHeader
        showLabels={showLabels}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        subject={subject}
      />
      {isOpen && <CollapseContent subject={subject} />}
    </Container>
  );
};

export default SubjectCollapse;
