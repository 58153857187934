import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import React from "react";

import { Button } from "components/Button";
import routeRoutes from "components/Routes/routes";
import { Stack } from "components/Stack";

import { DAY_FORMAT } from "consts/dates";

import { useCourse } from "../Context";

export const CourseBeyondDeadline: React.FC = () => {
  const { currentCourseProgress } = useCourse();
  const navigate = useNavigate();

  if (!currentCourseProgress?.deadline) return null;

  return (
    <Stack data-testid="lesson-inactive" $gap={16}>
      <h4>
        Realizacja tego kursu była możliwa do{" "}
        {format(new Date(currentCourseProgress.deadline), DAY_FORMAT)}
      </h4>
      <Button
        title="Moje kursy"
        variant="secondary"
        onClick={() => navigate(routeRoutes.eLearning)}
      />
    </Stack>
  );
};
