import { useEffect, useRef } from "react";

export const useOutsideClick = (callback?: () => void) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const callbackRef = useRef(callback);

  useEffect(() => {
    const handleClickEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape") callbackRef.current?.();
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (
        elementRef.current &&
        e.target &&
        !elementRef.current.contains(e.target as Node)
      ) {
        callbackRef.current?.();
      }
    };

    document.addEventListener("keydown", handleClickEsc);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleClickEsc);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [elementRef]);

  return { elementRef };
};
