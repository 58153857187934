import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { CourseProgressItemElementStatus } from "@escolalms/sdk/lib/types/api";
import { Col, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import React, { useContext, useEffect } from "react";

import { Button } from "components/Button";
import routeRoutes from "components/Routes/routes";
import { Stack } from "components/Stack";

import { useCourse } from "../Context";
import CourseSidebar from "../CourseSidebar";
import { LessonStateContent } from "./LessonStateContent";

const StyledCourse = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 54px);
`;

const CourseTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const CourseProgramLessons: React.FC = () => {
  const { currentTopic, currentLesson, flatTopics, currentCourseProgram } =
    useCourse();
  const { sendProgress } = useContext(EscolaLMSContext);

  const navigate = useNavigate();

  // scroll to top on every navigate
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentLesson?.id, currentTopic?.id]);

  // development thing
  if (process.env.NODE_ENV === "development") {
    //@ts-ignore
    window.resetProgress = () => {
      sendProgress(
        currentCourseProgram?.id!,
        (flatTopics ?? []).map((topic) => ({
          topic_id: Number(topic?.id),
          status: CourseProgressItemElementStatus.INCOMPLETE,
        }))
      );
    };
  }

  if (!currentCourseProgram) {
    return <div>Brak programu</div>;
  }

  if (!currentTopic) {
    return (
      <Stack $gap={40} $alignItems="center" $justifyContent="center">
        <h2>Brak tematu</h2>
        <Button
          title="Moje kursy"
          variant="secondary"
          onClick={() => navigate(routeRoutes.eLearning)}
        ></Button>
      </Stack>
    );
  }

  return (
    <StyledCourse data-testid="course-lessons">
      <div>
        <CourseTitle>Lekcja: {currentTopic.title}</CourseTitle>
        <Row>
          <Col lg={9}>
            <LessonStateContent />
          </Col>
          <Col lg={3}>
            <CourseSidebar />
          </Col>
        </Row>
      </div>
    </StyledCourse>
  );
};
