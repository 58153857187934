import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

import React from "react";

import { Button } from "components/Button";
import routeRoutes from "components/Routes/routes";
import { Stack } from "components/Stack";

const Title = styled.h4``;

export const LessonInactive: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Stack data-testid="lesson-inactive" $gap={16}>
      <Title>Ta lekcja jest nieaktywna</Title>
      <Button
        title="Moje kursy"
        variant="secondary"
        onClick={() => navigate(routeRoutes.eLearning)}
      />
    </Stack>
  );
};
