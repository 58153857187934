import { styled } from "styled-components";
import { device } from "theme/breakpoints";
import { getStyleBasedOnTheme } from "theme/utils";

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  gap: 11px;
  overflow: hidden;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    gap: 28px;
  }
`;

export const InfoBar = styled.div`
  background-color: ${({ theme }) =>
    getStyleBasedOnTheme(theme, {
      base: "#f2fcf8",
      contrast: theme.colors.gray,
    })};
  width: 100%;
  padding: 13px 20px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: 11px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const ContentColumn = styled.div``;

export const Grade = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr 1fr;
  gap: 11px;

  padding: 0 20px;
  width: 100%;

  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const GradeName = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const NormalText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 20px;
`;

export const GradePercentage = styled.div<{ $passed?: boolean }>`
  color: ${({ theme, $passed }) =>
    getStyleBasedOnTheme(theme, {
      base: $passed
        ? theme.colors.green
        : $passed === false
        ? theme.colors.red
        : theme.colors.black,
      contrast: theme.colors.primary,
    })};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  @media ${device.tablet} {
    margin: 0 0 0 auto;
  }
`;

export const GradeColor = styled.div<{ $passed?: boolean }>`
  color: ${({ theme, $passed }) =>
    getStyleBasedOnTheme(theme, {
      base: $passed
        ? theme.colors.green
        : $passed === false
        ? theme.colors.red
        : theme.colors.black,
      contrast: theme.colors.primary,
    })};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  @media ${device.tablet} {
    margin: 0 0 0 auto;
  }
`;

export const Grid = styled.div<{ $equal?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $equal }) =>
    $equal ? "1fr 1fr" : "max-content auto"};
  gap: 11px;

  padding: 0 20px;
  width: 100%;

  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const FrequencyTitle = styled.div`
  min-width: 90px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const FrequencyCount = styled.div`
  @media ${device.tablet} {
    margin: 0 0 0 auto;
  }
`;

export const FrequencyList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const FrequencyListItem = styled.li``;

export const CenteredContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Divider = styled.span`
  grid-column: 1 / 3;

  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.border};
`;
