import { styled } from "styled-components";

export const Container = styled.div<{ $visible: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 65px);
  height: 100vh;
  position: fixed;
  top: 0;
  right: ${({ $visible }) => ($visible ? "0" : "-100%")};
  z-index: 1000;
  transition: right 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  padding: 13px 0;
`;

export const SettingsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 18px 15px 18px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: calc(50% - 12px);
    right: 48px;
    transform: translateY(-50%);
    width: 1px;
    height: calc(100% + 26px);
    background-color: ${({ theme }) => theme.colors.border};
  }
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.textPrimary};
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

export const MenuList = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const MenuItem = styled.div`
  width: 100%;
  a {
    padding: 26px 20px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: ${({ theme }) => theme.fontSizes.large};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
`;
