import styled from "styled-components";
import { getStyleBasedOnTheme } from "theme/utils";

import { Row } from "components/Row";

export const RowContainer = styled(Row)`
  margin: 18px 0px 14px 0px;
  & label {
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSizes.medium};
    color: ${({ theme }) =>
      getStyleBasedOnTheme(theme, {
        base: theme.colors.secondary,
        contrast: theme.colors.textPrimary,
      })};
  }
`;
