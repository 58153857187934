import { API } from "@escolalms/sdk/lib";

type Nullable<T> = T | null;

export type NormalizedError = {
  name: Nullable<string>;
  type: Nullable<string>;
  data: Nullable<API.DefaultResponseError>;
  // TODO
  request: Nullable<unknown>;
  // TODO
  response: Nullable<unknown>;
};

function isObject(v: unknown): v is object {
  return typeof v === "object" && v !== null;
}

function isDefaultResponseError(v: unknown): v is API.DefaultResponseError {
  return (
    isObject(v) &&
    "success" in v &&
    typeof v.success === "boolean" &&
    "message" in v &&
    typeof v.message === "string"
  );
}

function createEmptyError(): NormalizedError {
  return { name: null, data: null, request: null, response: null, type: null };
}

export function normalizeError(error: unknown): NormalizedError {
  let result = createEmptyError();

  if (isObject(error)) {
    if ("name" in error && typeof error.name === "string") {
      result.name = error.name;
    }
    if ("type" in error && typeof error.type === "string") {
      result.type = error.type;
    }
    if ("data" in error && isDefaultResponseError(error.data)) {
      result.data = error.data;
    }
    if ("request" in error) {
      result.request = error.request;
    }
    if ("response" in error) {
      result.response = error.response;
    }
  }

  return result;
}
