import type { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { addProject, fetchProjects } from "@escolalms/sdk/lib/services/project";

import React, { useCallback, useContext, useEffect, useState } from "react";

import { MarkdownRenderer } from "components/MarkdownRenderer";
import { Upload } from "components/Upload";

import { ProjectsList } from "./components/ProjectsList";
import { Wrapper } from "./styles";
import type { ProjectsData } from "./types";

export interface ProjectPlayerProps {
  course_id: number;
  topic: API.TopicProject;
  onSuccess?: () => void;
  onError?: () => void;
  className?: string;
}

const ProjectPlayer: React.FC<ProjectPlayerProps> = ({
  course_id,
  topic,
  onSuccess,
  onError,
  className,
}) => {
  const { token, apiUrl } = useContext(EscolaLMSContext);
  const [projects, setProjects] = useState<ProjectsData>({
    data: [],
    loading: false,
  });

  const [isUploading, setIsUploading] = useState(false);

  const refreshProjects = useCallback(() => {
    if (!token) return console.error("noToken");

    setProjects((prev) => ({ ...prev, loading: true }));
    fetchProjects(apiUrl, token, { course_id, topic_id: topic?.id })
      .then((res) => {
        if (res.success) {
          setProjects((prev) => ({
            ...prev,
            data: res.data,
          }));
        }
      })
      .catch((err) => console.warn(err))
      .finally(() => setProjects((prev) => ({ ...prev, loading: false })));
  }, [token, apiUrl, course_id, topic?.id]);

  useEffect(() => {
    refreshProjects();
  }, [refreshProjects]);

  const onProjectFileSelect = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget.files?.[0] || !token) return;

      try {
        setIsUploading(true);
        const body: API.AddProjectBody = {
          topic_id: topic?.id + "",
          file: e.currentTarget.files[0],
        };

        await addProject(apiUrl, token, body);
        refreshProjects();
        onSuccess?.();
        e.target.value = "";
      } catch {
        onError?.();
      } finally {
        setIsUploading(false);
      }
    },
    [token, topic?.id, apiUrl, refreshProjects, onSuccess, onError]
  );

  return (
    <Wrapper className={className} data-testid="project-player">
      {topic.description && (
        <MarkdownRenderer>{topic.description}</MarkdownRenderer>
      )}
      <Upload
        name="project_file"
        onChange={onProjectFileSelect}
        disabled={isUploading}
        loading={isUploading}
      />
      <ProjectsList projects={projects} onDeleteSuccess={refreshProjects} />
    </Wrapper>
  );
};

export default ProjectPlayer;
