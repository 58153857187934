import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

import React, { useContext } from "react";

import { Button } from "components/Button";
import { CourseProvider } from "components/Course/Context";
import { CourseProgramLessons } from "components/Course/CourseProgramLessons";
import { LessonStateContextProvider } from "components/Course/LessonStateContext";
import ScormPlayer from "components/Course/Players/ScormPlayer";
import Layout from "components/Layout";
import Loader, { LoaderContainer } from "components/Loader";
import routeRoutes from "components/Routes/routes";

const CourseProgramScorm: React.FC<{ program: API.CourseProgram }> = ({
  program,
}) => {
  const sco = program?.scorm_sco;
  const uuid = sco?.uuid;

  if (!sco && !uuid) {
    return <React.Fragment />;
  }

  return (
    <ScormPlayer
      value={{
        title: sco.title,
        uuid: sco.uuid,
      }}
    />
  );
};

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 50vh;

  button {
    align-self: unset;
  }
`;

const CourseContentWrapper = styled.div`
  padding-bottom: 40px;
`;

const CoursePageContent: React.FC = () => {
  const { program } = useContext(EscolaLMSContext);
  const navigate = useNavigate();

  if (program?.loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (program?.error) {
    console.log("NoProgram");
    return null;
  }

  if (!program?.value?.lessons?.length) {
    return (
      <Layout>
        <InfoContainer>
          <h2>Brak lekcji dla tego kursu</h2>
          <Button
            title="Moje kursy"
            variant="secondary"
            onClick={() => navigate(routeRoutes.eLearning)}
          />
        </InfoContainer>
      </Layout>
    );
  }

  if (program?.value?.lessons?.length) {
    return (
      <LessonStateContextProvider>
        <Layout title="E-learning">
          <CourseContentWrapper>
            <CourseProgramLessons />
          </CourseContentWrapper>
        </Layout>
      </LessonStateContextProvider>
    );
  }

  if (program?.value?.scorm_sco_id) {
    return (
      <Layout>
        <CourseProgramScorm program={program.value} />
      </Layout>
    );
  }
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
};

const CoursePage: React.FC = () => (
  <CourseProvider>
    <CoursePageContent />
  </CourseProvider>
);

export default CoursePage;
