import { styled } from "styled-components";

import React from "react";

type Props = {
  lastPage: number;
  currentPage: number;
  onNextPage?: () => void;
  onPrevPage?: () => void;
  onPageClick?: (page: number) => void;
};

const Page = styled.button<{
  $active?: boolean;
}>`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.secondary : theme.colors.black};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
`;

const ArrowButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0;
  color: ${({ theme }) => theme.colors.secondary};
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.border};
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

const Pagination: React.FC<Props> = ({
  lastPage,
  currentPage,
  onNextPage,
  onPrevPage,
  onPageClick,
}) => {
  return (
    <Container data-testid="pagination">
      <ArrowButton onClick={onPrevPage} disabled={currentPage === 1}>
        {"<"}
      </ArrowButton>

      {Array.from(Array(lastPage).keys()).map((page) => (
        <Page
          $active={page + 1 === currentPage}
          key={page}
          onClick={() => onPageClick && onPageClick(page + 1)}
        >
          {page + 1}
        </Page>
      ))}
      <ArrowButton onClick={onNextPage} disabled={currentPage === lastPage}>
        {">"}
      </ArrowButton>
    </Container>
  );
};

export default Pagination;
