import { Col, Row } from "react-grid-system";
import styled from "styled-components";

import React from "react";

import { MarkdownRenderer } from "components/MarkdownRenderer";

import { useCourse } from "../Context";
import CourseDownloads from "../CourseDownloads";
import CourseProgramContent from "../CourseProgramContent";

const CourseDescription = styled.div`
  p,
  ul,
  li,
  a {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

const CourseContent = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid ${({ theme }) => theme.colors.gray};

    td {
      padding: 10px;
      border: 1px solid ${({ theme }) => theme.colors.gray};
    }

    tr {
      &:nth-child(even) {
        background-color: ${({ theme }) => theme.colors.border};
      }
    }

    th {
      padding: 10px;
      border: 1px solid ${({ theme }) => theme.colors.gray};
      background-color: ${({ theme }) => theme.colors.gray};
    }
  }

  blockquote {
    border-left: 5px solid ${({ theme }) => theme.colors.primary};
    padding: 5px;
    margin-left: 20px;
    font-style: italic;
    background-color: ${({ theme }) => theme.colors.border};
  }
`;

const CourseSummary = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding-top: 20px;
`;

export const CourseInProgress: React.FC = () => {
  const { currentLesson, currentTopic } = useCourse();

  const columnWidth = currentLesson?.summary && currentTopic?.summary ? 6 : 12;

  return (
    <CourseContent data-testid="course-in-progress">
      <div className="course-program-player-content">
        {currentTopic?.introduction && (
          <MarkdownRenderer>{currentTopic.introduction}</MarkdownRenderer>
        )}
        <div
          className="course-program-player-content__wrapper"
          style={currentTopic?.json?.wrapperStyle ?? {}}
        >
          <CourseProgramContent key={currentTopic?.id} />
        </div>
      </div>
      <CourseDescription
        className="course-program-content__container"
        style={{
          margin: "50px 0",
        }}
      >
        <Row>
          {currentLesson?.summary && (
            <Col sm={12} md={columnWidth} lg={columnWidth}>
              <CourseSummary>
                <MarkdownRenderer>{currentLesson.summary}</MarkdownRenderer>
              </CourseSummary>
            </Col>
          )}
          {currentTopic?.summary && (
            <Col sm={12} md={columnWidth} lg={columnWidth}>
              <CourseSummary>
                <MarkdownRenderer>{currentTopic?.summary}</MarkdownRenderer>

                {currentTopic?.resources?.length && (
                  <CourseDownloads
                    resources={currentTopic.resources}
                    subtitle={currentTopic.introduction || ""}
                  />
                )}
              </CourseSummary>
            </Col>
          )}
        </Row>
      </CourseDescription>
    </CourseContent>
  );
};
