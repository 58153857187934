export type SharedThemeValues = {
  fonts: {
    primary: string;
  };
  fontSizes: {
    small: string;
    medium: string;
    large: string;
    xlarge: string;
  };
  fontWeights: {
    normal: number;
    bold: number;
  };
};

const sharedThemeValues: SharedThemeValues = {
  fonts: {
    primary: "Lato, sans-serif",
  },
  fontSizes: {
    small: "10px",
    medium: "13px",
    large: "15px",
    xlarge: "24px",
  },
  fontWeights: {
    normal: 400,
    bold: 700,
  },
};

// Those are base sizes * 1.25
export const bigFontSizes: SharedThemeValues["fontSizes"] = {
  small: "12.5px",
  medium: "16.25px",
  large: "18.75px",
  xlarge: "30px",
};

export default sharedThemeValues;
