import { ButtonHTMLAttributes, FC } from "react";

import Icon, { IconName } from "components/Icon";
import { Row } from "components/Row";

import { ButtonVariant, StyledButton } from "./styles";

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "title"> {
  variant?: ButtonVariant;
  title?: string;
  icon?: IconName;
  iconGap?: number;
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  title,
  variant,
  icon,
  iconGap,
  loading,
  ...props
}) => (
  <StyledButton
    disabled={props.disabled || loading}
    $loading={loading}
    $variant={variant}
    {...props}
  >
    {icon === undefined ? (
      title
    ) : (
      <Row $gap={iconGap}>
        {title}
        <Icon name={icon} />
      </Row>
    )}
  </StyledButton>
);
