import { API } from "@escolalms/sdk/lib";
import styled from "styled-components";
import { CourseAgendaSharedComponentProps } from "types/common.types";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import useMediaQuery from "hooks/UseMediaQuery";

import { useCourse } from "../../Context";
import CourseAgendaTopic from "./CourseAgendaTopic";
import { LockIcon } from "./Icons";

interface CourseAgendaLessonProps extends CourseAgendaSharedComponentProps {
  lesson: API.Lesson;
  index: number;
  defaultOpen?: boolean;
  children?: React.ReactNode;
  dontBlock?: boolean;
}

const Container = styled.div``;

const StyledLessonItem = styled.div`
  header {
    font-style: italic;
    position: relative;
  }
  ul {
    padding: 0 0 0 5px;
    position: relative;

    li {
      position: relative;
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
      padding: 0 0 15px 0;

      &.lesson-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(2px);
        background: rgba(255, 255, 255, 0.5);
        z-index: 1;
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px;
        text-align: center;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        border-bottom: none;

        svg {
          fill: ${({ theme }) => theme.colors.black};
        }
      }
    }
  }
`;

const StyledList = styled.ul`
  padding-left: 5px !important;
  margin: 0;
`;

const GRID_PURPOSES_NUMBER = 2;

const CourseAgendaLesson: React.FC<CourseAgendaLessonProps> = ({
  lesson,
  defaultOpen = false,
  children,
  onNextTopicClick,
  onTopicClick,
  dontBlock,
}) => {
  const { courseAgendaData } = useCourse();

  const mobileView = useMediaQuery("mobile");
  const [open, setOpen] = useState(defaultOpen);

  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    if (defaultOpen && !open) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOpen]);

  const lessonHasLockedTopic = useMemo(
    () =>
      lesson?.topics?.some((lesson) =>
        courseAgendaData?.lockedTopicsIds.includes(lesson.id)
      ) ?? false,
    [lesson?.topics, courseAgendaData?.lockedTopicsIds]
  );

  const totalHeightOfOverlay = useMemo(
    () =>
      (lesson?.topics ?? []).filter((t) =>
        courseAgendaData?.lockedTopicsIds.includes(t.id)
      ).length + 1,
    [courseAgendaData?.lockedTopicsIds, lesson?.topics]
  );

  if (!courseAgendaData) {
    return null;
  }

  const {
    firstBlockedTopic,
    firstBlockedTopicIndex,
    lockedLessonsIds,
    blockedTopicIndexInParentLesson,
  } = courseAgendaData;

  const gridStartingPosition = firstBlockedTopicIndex + GRID_PURPOSES_NUMBER;

  const isLessonLocked = lockedLessonsIds.includes(lesson.id);

  const overlayTextWhenLessonIsLocked =
    totalHeightOfOverlay > 2
      ? "Ukończ wcześniej poprzednie tematy"
      : "Najpierw ukończ wymagane tematy";

  const overlayTextWhenTopicIsLocked =
    totalHeightOfOverlay > 1
      ? "Najpierw ukończ poprzednie tematy "
      : "Temat jest zablokowany";

  return (
    <Container data-testid="agenda-lessons">
      <StyledLessonItem>
        {!mobileView && (
          <header>
            <div
              onClick={onClick}
              onKeyDown={(e) => e.key === "Enter" && onClick()}
              role="button"
              tabIndex={0}
            >
              <p>{lesson.title}</p>
            </div>
          </header>
        )}
        <StyledList>
          {!dontBlock && (lessonHasLockedTopic || isLessonLocked) && (
            <li
              className="lesson-overlay"
              style={{
                gridRowStart: isLessonLocked ? 1 : gridStartingPosition,
              }}
            >
              <LockIcon />
              {isLessonLocked ? (
                <>
                  <p>{overlayTextWhenLessonIsLocked}</p>
                  {totalHeightOfOverlay > 2 && (
                    <p>
                      Temat do ukończenia:{" "}
                      <strong>{firstBlockedTopic?.title}</strong>
                    </p>
                  )}
                </>
              ) : (
                <p>
                  {overlayTextWhenTopicIsLocked}
                  <strong>
                    {totalHeightOfOverlay < 3 &&
                      totalHeightOfOverlay > 1 &&
                      blockedTopicIndexInParentLesson !== undefined &&
                      ` nr. ${blockedTopicIndexInParentLesson + 1} `}
                    {totalHeightOfOverlay >= 3 &&
                      ` (${firstBlockedTopic?.title}) `}
                  </strong>
                  <span>
                    {totalHeightOfOverlay > 2 &&
                      "aby uzyskać dostęp do tej lekcji"}
                  </span>
                </p>
              )}
            </li>
          )}
          {children}
          {lesson.topics?.map((topic, topicIndex) => (
            <CourseAgendaTopic
              clickable
              key={topicIndex}
              topic={topic}
              index={topicIndex + 1}
              onTopicClick={onTopicClick}
              onNextTopicClick={onNextTopicClick}
            />
          ))}
        </StyledList>
      </StyledLessonItem>
    </Container>
  );
};

export default CourseAgendaLesson;
