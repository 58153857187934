import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";

import React, { createContext, useContext, useMemo, useState } from "react";

type ISchoolContext = {
  semesterId: number | null;
  setSemesterId: (id: number | null) => void;
  academicYearId: number | null;
  setAcademicYearId: (id: number | null) => void;
  selectedSemester: API.SemesterData | null | undefined;
};

type Props = {
  children: React.ReactNode;
};

export const SchoolContext = createContext<ISchoolContext>(null!);

export const SchoolContextProvider = ({ children }: Props) => {
  const [semesterId, setSemesterId] = useState<number | null>(null);
  const [academicYearId, setAcademicYearId] = useState<number | null>(null);
  const { semesters } = useContext(EscolaLMSContext);

  const selectedSemester = useMemo(() => {
    if (!semesters?.list) {
      return null;
    }

    return semesters.list.find((item) => item.id === semesterId);
  }, [semesters, semesterId]);

  return (
    <SchoolContext.Provider
      value={{
        semesterId,
        setSemesterId,
        academicYearId,
        setAcademicYearId,
        selectedSemester,
      }}
    >
      {children}
    </SchoolContext.Provider>
  );
};
