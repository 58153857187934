import { css, styled } from "styled-components";
import { getStyleBasedOnTheme } from "theme/utils";

export const Card = styled.div<{ disabled?: boolean }>`
  width: 240px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  height: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      ${CardButton} {
        cursor: not-allowed;
      }
      ${Title}, ${Teacher} {
        opacity: 0.5;
      }
      ${CardImage} {
        opacity: 0.15;
      }
    `};
  pointer-events: ${({ disabled }) => disabled && "none"};
`;

export const CardButton = styled.div`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

export const CardImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 237px;
`;

export const DisabledIcon = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) =>
    getStyleBasedOnTheme(theme, {
      base: "#A0A0A0",
      contrast: theme.colors.textPrimary,
    })};
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 4px solid transparent;
`;

export const Content = styled.div`
  padding: 11px 12px 7px;
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: 4px;
`;

export const Teacher = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-bottom: 21px;
`;

export const DateComponent = styled.div`
  font-size: 11px;
  color: ${({ theme }) =>
    getStyleBasedOnTheme(theme, {
      base: theme.colors.gray,
      contrast: theme.colors.textPrimary,
    })};
  opacity: 0.63;
  margin-top: 6px;
`;

export const DisabledText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-transform: uppercase;
`;
