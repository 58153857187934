import styled from "styled-components";
import { fadeIn } from "theme/animations";

import { Stack } from "components/Stack";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  animation: ${fadeIn} 0.3s ease-in;
`;

export const ModalHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

export const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: 20px;
  max-width: 400px;
  width: 100%;
  max-height: 80vh;
  overflow: auto;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  position: relative;
`;

export const CloseButton = styled.span`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.gray};

  &:hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const UserInfo = styled(Stack)`
  margin-bottom: 16px;
  & h4 {
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
  & p {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    margin: 0px;
  }
`;

export const SubjectInfo = styled(Stack)`
  margin: 16px 0px;
  & h4 {
    font-size: ${({ theme }) => theme.fontSizes.large};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
  & p {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    margin: 0px;
  }
`;
