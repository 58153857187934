import { API } from "@escolalms/sdk/lib";
import styled from "styled-components";
import {
  CourseAgendaSharedComponentProps,
  ExtendableStyledComponent,
} from "types/common.types";

import React, { useMemo } from "react";

import { useCourse } from "../Context";
import CourseAgendaLesson from "./_components/CourseAgendaLesson";

type CourseAgendaProps = CourseAgendaSharedComponentProps &
  ExtendableStyledComponent;

const StyledSection = styled("section")`
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
const ProgressInfo = styled.div`
  & p {
    margin: 0;
    text-align: right;
  }
`;

const RecursiveLessons: React.FC<
  CourseAgendaProps & { lessons: API.Lesson[] }
> = ({ lessons, onNextTopicClick, onTopicClick }) => {
  const {
    currentTopic,
    courseAgendaData,
    currentLessonParentsIds,
    finishedTopicsIds,
  } = useCourse();

  return (
    <>
      {lessons.map((lesson, index) => (
        <CourseAgendaLesson
          defaultOpen={lesson.topics?.some(
            (topic) => topic.id === currentTopic?.id
          )}
          key={lesson.id}
          index={index}
          lesson={lesson}
          dontBlock={
            lesson.topics?.every((t) => finishedTopicsIds?.includes(t.id)) ||
            currentLessonParentsIds?.includes(lesson.id) ||
            lesson.id === courseAgendaData?.firstBlockedTopic?.lesson_id ||
            courseAgendaData?.firstBlockedTopicLessonIndex === -1 ||
            courseAgendaData?.blockedTopicIndexInParentLesson === -1 ||
            courseAgendaData?.firstBlockedTopicIndex === -1
          }
          onTopicClick={onTopicClick}
          onNextTopicClick={onNextTopicClick}
        >
          <RecursiveLessons
            lessons={lesson?.lessons ?? []}
            onNextTopicClick={onNextTopicClick}
            onTopicClick={onTopicClick}
          />
        </CourseAgendaLesson>
      ))}
    </>
  );
};

export const CourseAgenda: React.FC<CourseAgendaProps> = ({
  mobile = false,
  onTopicClick,
  onNextTopicClick,
}) => {
  const { finishedTopicsIds, flatTopics, currentCourseProgram } = useCourse();

  const percentage = useMemo(() => {
    return Math.round(
      ((finishedTopicsIds?.length ?? 0) / (flatTopics?.length ?? 0)) * 100
    );
  }, [flatTopics, finishedTopicsIds]);

  return (
    <StyledSection data-testid="course-agenda">
      {!mobile && (
        <header>
          <ProgressInfo>
            <p>Ukończono {percentage}%</p>
          </ProgressInfo>
        </header>
      )}
      <article>
        <RecursiveLessons
          onTopicClick={onTopicClick}
          onNextTopicClick={onNextTopicClick}
          lessons={currentCourseProgram?.lessons ?? []}
        />
      </article>
    </StyledSection>
  );
};

export default CourseAgenda;
