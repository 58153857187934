import styled from "styled-components";

import React, { useMemo } from "react";

import { Row } from "components/Row";

export type QuizScoreColor = "systemDanger" | "systemPositive";

interface Props {
  result?: number;
  max?: number;
}

const Wrapper = styled(Row)`
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.border};

  & p {
    margin: 0px;
  }
`;

const Progress = styled.span<{
  $percentage: number;
}>`
  position: relative;
  display: block;
  width: 52px;
  height: 12px;
  background-color: ${({ theme }) => theme.secondaryColor};
  border-radius: 4px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ $percentage }) => $percentage * 52}px;
    height: 12px;
    background-color: ${({ theme }) => theme.primaryColor};
  }
`;

const GiftQuizScore: React.FC<Props> = ({ result, max }) => {
  const percentage = useMemo(() => {
    const percentageRes = (result ?? 0) / (max ?? 0);
    return Number.isNaN(percentageRes) ? 0 : percentageRes;
  }, [max, result]);

  return (
    <Wrapper data-testid="gift-quiz-score" $gap={12} $alignItems="center">
      <Row $gap={6} $alignItems="center">
        <p>Twój wynik</p>
      </Row>
      <Row $gap={8} $alignItems="center">
        <Progress $percentage={percentage} />
        <p>
          {result}/{max}
        </p>
      </Row>
    </Wrapper>
  );
};

export default GiftQuizScore;
