import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import {
  HashRouter,
  Navigate,
  Outlet,
  Route,
  Routes as Switch,
} from "react-router-dom";

import { PropsWithChildren, ReactElement, Suspense, useContext } from "react";

import CertificatesPage from "pages/Certificates";
import ELearningPage from "pages/ELearning";
import CoursePage from "pages/ELearning/Course";
import ErrorPage from "pages/ErrorPage";
import Login from "pages/Login";
import SchedulePage from "pages/Schedule";
import Subjects from "pages/Subjects";
import TeamPage from "pages/Team";

import routes from "./routes";

const ProtectedRoute = ({
  redirectPath = "/login",
}: {
  redirectPath?: string;
}) => {
  const { token } = useContext(EscolaLMSContext);
  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

const Routes: React.FC<PropsWithChildren> = ({ children }): ReactElement => {
  const {
    login,
    subjects,
    eLearning,
    schedule,
    team,
    certificates,
    course,
    coursePre,
  } = routes;

  return (
    <HashRouter>
      <Suspense fallback={"Loading..."}>
        <Switch>
          <Route path={login} element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path={subjects} element={<Subjects />} />
            <Route path={eLearning} element={<ELearningPage />} />
            <Route path={course} element={<CoursePage />} />
            <Route path={coursePre} element={<CoursePage />} />
            <Route path={schedule} element={<SchedulePage />} />
            <Route path={team} element={<TeamPage />} />
            <Route path={certificates} element={<CertificatesPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Switch>
      </Suspense>
      {children}
    </HashRouter>
  );
};

export default Routes;
