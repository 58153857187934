import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { getStyleBasedOnTheme } from "theme/utils";

import Icon from "components/Icon";

import { menuItems } from "consts";

const Container = styled.div`
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 192px;
  height: 100%;
  padding: 16px 7px;
  background: ${({ theme }) =>
    getStyleBasedOnTheme(theme, {
      base: theme.colors.primary,
      contrast: theme.colors.gray,
    })};
  border-radius: 0 0 89px 0;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10px;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 18px;
`;

const MenuItemWrapper = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  width: 100%;

  a {
    display: block;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: ${({ theme }) => theme.fontSizes.large};
    width: 100%;
    padding: 15px 25px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0);
  }

  &:hover {
    a {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;

const Sidebar = () => (
  <Container data-testid="sidebar" id="sidebar">
    <Logo>
      <Icon name="logoVertical" />
    </Logo>
    <Menu>
      {menuItems.map((item) => (
        <MenuItemWrapper key={item.name}>
          <Link to={item.link}>{item.name}</Link>
        </MenuItemWrapper>
      ))}
    </Menu>
  </Container>
);

export default Sidebar;
