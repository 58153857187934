import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { useEffect } from "react";

if (!("process" in window)) {
  // @ts-ignore
  window.process = {};
}
const SENTRY_DSN =
  window.REACT_APP_SENTRYDSN || process?.env?.REACT_APP_SENTRYDSN || "";

function configSentry() {
  if (SENTRY_DSN && !window.location.hostname.includes("localhost")) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          // See docs for support of different versions of variation of react router
          // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0, // Set the sample rate for tracing (1.0 means capture all transactions)
    });
  }
  return null;
}
export default configSentry();
