import { styled } from "styled-components";

export const Container = styled.div`
  padding: 16px 20px 18px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-left: 3px solid ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  max-width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0 0 8px 0;
`;

export const DateWrapper = styled.div`
  margin-bottom: 65px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;
