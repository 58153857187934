import styled from "styled-components";
import { ExtendableStyledComponent } from "types/common.types";

import * as React from "react";
import { RefObject, useCallback, useMemo } from "react";

const notTextAreaProps = {
  theme: undefined,
  label: undefined,
};

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    ExtendableStyledComponent {
  label?: string | React.ReactNode;
  textAreaRef?: RefObject<HTMLTextAreaElement>;
}

const StyledTextArea = styled.div<TextAreaProps>`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  width: 100%;

  * {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-family: ${({ theme }) => theme.fonts.primary};
    outline: none;
    box-sizing: content-box;
  }
  textarea {
    background: ${({ theme }) => theme.colors.primaryBackground};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 10px;

    color: ${({ theme, disabled }) => {
      if (disabled) {
        return theme.colors.border;
      }
      return theme.colors.black;
    }};

    &:disabled {
      cursor: not-allowed;
    }
  }
  label {
    pointer-events: none;
    transform-origin: left top;
    max-width: calc(100% - 24px);
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(12px, 12px) scale(1);
    z-index: 1;
    transition: 0.2s all;
    color: ${({ theme, disabled }) => {
      if (disabled) {
        return theme.colors.border;
      }
      return theme.colors.black;
    }};
  }
  .textarea-container {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    &.filled,
    &:focus-within {
      label {
        transform: translate(12px, -7px) scale(0.75);
        color: ${({ theme, disabled }) => {
          if (disabled) {
            return theme.colors.border;
          }
          return theme.colors.black;
        }};
      }
    }
  }
`;

export const TextArea: React.FC<TextAreaProps> = (props) => {
  const { label, disabled } = props;
  const generateRandomTextAreatId = useMemo(() => {
    const randomString = (Math.random() + 1).toString(36).substring(3);
    return `textarea-id-${randomString}`;
  }, []);

  const renderLabel = useCallback(() => {
    if (label) {
      return <label htmlFor={generateRandomTextAreatId}>{label}</label>;
    }
    return <></>;
  }, [generateRandomTextAreatId, label]);

  return (
    <StyledTextArea disabled={disabled} data-testid="textarea">
      <div>
        {renderLabel()}
        <textarea
          {...props}
          {...notTextAreaProps}
          id={label ? generateRandomTextAreatId : undefined}
        >
          {props.value}
        </textarea>
      </div>
    </StyledTextArea>
  );
};
