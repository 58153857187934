import type { DefaultTheme } from "styled-components";

import sharedThemeValues from "./shared";

const baseTheme: DefaultTheme = {
  name: "base",
  fontSize: "normal",
  colors: {
    primary: "#006341",
    secondary: "#B9975B",
    black: "#212121",
    gray: "#676767",
    white: "#FFFFFF",
    primaryBackground: "#F8F8F8",
    secondaryBackground: "#FFFFFF",
    primaryButton: "#4A51BB",
    border: "#E0E0E0",
    green: "#3EB200",
    red: "#9B3939",
    textPrimary: "#212121",
    textSecondary: "#FFFFFF",
    textAccent: "#FFFFFF",
    error: "#FF4545",
  },
  shadows: {
    primary: "0px 5px 25px rgba(0, 0, 0, 0.15)",
  },
  ...sharedThemeValues,
};

export default baseTheme;
