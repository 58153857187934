import { styled } from "styled-components";

import React from "react";

type Props = {
  id: string;
  name: string;
  options: {
    label: string;
    value: string;
  }[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  disabled?: boolean;
  variant?: "default" | "noBorder";
  sufix?: boolean;
  small?: boolean;
  color?: string;
  defaultValue?: string;
};

const StyledSelect = styled.select<{
  $variant?: "default" | "noBorder";
  $sufix?: boolean;
  $small?: boolean;
  $color?: string;
}>`
  cursor: pointer;
  max-width: fit-content;
  background: transparent;
  border: ${({ theme, $variant }) => {
    if ($variant === "noBorder") {
      return "none";
    }
    return `1px solid ${theme.colors.border}`;
  }};
  border-radius: 4px;
  width: 100%;
  font-weight: ${({ $small, theme }) =>
    $small ? theme.fontWeights.normal : theme.fontWeights.bold};
  font-size: ${({ theme, $small }) =>
    $small ? theme.fontSizes.medium : theme.fontSizes.large};
  color: ${({ theme, $color }) => $color || theme.colors.textPrimary};
  padding: ${({ $sufix }) => ($sufix ? "0px 55px 0px 0px" : "0px")};

  &:focus {
    border-color: #aaa;
  }
`;

const Container = styled.div`
  position: relative;
  max-width: fit-content;
  cursor: pointer;
  z-index: 1;
`;

const Sufix = styled.span`
  position: absolute;
  z-index: -1;
  right: 20px;
  top: 55%;
  transform: translateY(-50%);
  font-size: ${(props) => props.theme.fontSizes.small};
  color: ${(props) => props.theme.colors.textPrimary};
  opacity: 0.6;
`;

const Label = styled.label`
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
`;

const Select: React.FC<Props> = ({
  options,
  name,
  id,
  onChange,
  label,
  disabled,
  variant,
  sufix,
  small,
  color,
  defaultValue,
}) => {
  return (
    <Container data-testid="select">
      {label && <Label htmlFor={id}>{label}</Label>}
      <StyledSelect
        $variant={variant}
        disabled={disabled}
        name={name}
        id={id}
        onChange={onChange}
        $sufix={sufix}
        $small={small}
        $color={color}
        defaultValue={defaultValue}
      >
        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </StyledSelect>
      {sufix && <Sufix>wybierz</Sufix>}
    </Container>
  );
};

export default Select;
