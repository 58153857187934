import styled, { css } from "styled-components";

export const basicTextStyle = css`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 0px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  min-height: 260px;
  padding: 10px 16px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
`;

export const CardName = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0px;
`;

export const CardSubject = styled.p`
  ${basicTextStyle}
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const CardPlace = styled.p`
  ${basicTextStyle}
`;
