import { API } from "@escolalms/sdk/lib";
import styled from "styled-components";
import { CourseAgendaSharedComponentProps } from "types/common.types";

import React, { useCallback } from "react";

import { Button } from "components/Button";
import { Row } from "components/Row";

import { useCourse } from "../../Context";
import { useLessonStateContext } from "../../LessonStateContext";
import { CurrentIcon, FinishedIcon, PendingIcon } from "./Icons";

const StyledRow = styled(Row)`
  cursor: pointer;

  & > svg {
    flex-shrink: 0;
  }
  & p {
    margin: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: underline;
  }
`;
const ListItem = styled.li`
  margin-bottom: 15px;
  list-style: none;

  button {
    margin-top: 10px;
    max-width: unset !important;
    width: 100%;
  }
`;

export interface CourseAgendaTopicProps
  extends CourseAgendaSharedComponentProps {
  index: number;
  topic: API.Topic;
  clickable: boolean;
}

type TopicMode = "current" | "pending" | "finished";

const TopicIcon: React.FC<{ mode: TopicMode }> = ({ mode }) => {
  switch (mode) {
    case "current":
      return <CurrentIcon />;
    case "finished":
      return <FinishedIcon />;
    case "pending":
      return <PendingIcon />;
  }
};

function determineMode(
  topicId: number,
  finishedTopicsIds: number[],
  currentTopicId: number | undefined
): TopicMode {
  if (currentTopicId === topicId) {
    return "current";
  } else if (finishedTopicsIds.includes(topicId)) {
    return "finished";
  }

  return "pending";
}

const CourseAgendaTopic: React.FC<CourseAgendaTopicProps> = ({
  index,
  topic,
  clickable,
  onTopicClick,
  onNextTopicClick,
}) => {
  const { state: lessonState } = useLessonStateContext();

  const {
    isNextTopicButtonDisabled,
    finishedTopicsIds,
    flatTopics,
    currentTopic,
    completeCurrentTopic,
  } = useCourse();

  const mode = determineMode(
    topic.id,
    finishedTopicsIds ?? [],
    currentTopic?.id
  );

  const { changeLessonState } = useLessonStateContext();

  const onClick = useCallback(() => {
    if (mode !== "current") {
      onTopicClick(topic);
    }
  }, [mode, onTopicClick, topic]);

  const isTopicFinished = finishedTopicsIds?.includes(topic.id);
  const isLastNotEndedTopic =
    (flatTopics?.length ?? 0) - 1 === (finishedTopicsIds?.length ?? 0);
  const isLastTopic = flatTopics?.at(-1)?.id === topic.id;
  const isClickableLessonState =
    lessonState === "course-in-progress" || lessonState === "course-finished";

  return (
    <ListItem data-testid="agenda-topic">
      <StyledRow
        $alignItems="center"
        $gap={6}
        tabIndex={clickable ? 0 : -1}
        onClick={() => clickable && onClick()}
        onKeyDown={(e: React.KeyboardEvent) =>
          clickable && e.key === "Enter" && onClick()
        }
        role="button"
      >
        <TopicIcon mode={mode} />
        <strong>{index}. </strong>
        <p>{topic.title}</p>
      </StyledRow>

      {mode === "current" && !isTopicFinished && isLastNotEndedTopic && (
        <Button
          title="Ukończ"
          variant="secondary"
          disabled={isNextTopicButtonDisabled || !isClickableLessonState}
          onClick={() => {
            if (!clickable) return;
            completeCurrentTopic?.();
            changeLessonState("course-finished");
          }}
        />
      )}

      {mode === "current" && !isTopicFinished && !isLastNotEndedTopic && (
        <Button
          title="Zaznacz jako wykonane"
          variant="secondary"
          disabled={isNextTopicButtonDisabled || !isClickableLessonState}
          onClick={completeCurrentTopic}
        />
      )}

      {mode === "current" && isTopicFinished && !isLastTopic && (
        <Button
          title="Następna lekcja"
          variant="secondary"
          disabled={!isClickableLessonState}
          onClick={onNextTopicClick}
        />
      )}
    </ListItem>
  );
};

export default CourseAgendaTopic;
