import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { toast } from "react-toastify";
import { TutorType } from "types/team.types";

import { FC, useCallback, useContext, useState } from "react";

import { AvatarImage } from "components/Avatar/AvatarImage";
import { Button } from "components/Button";
import { Stack } from "components/Stack";

import { Card, CardName, CardPlace, CardSubject } from "./styles";

type CardProps = {
  tutor: TutorType;
};

export const CardComponent: FC<CardProps> = ({ tutor }) => {
  const { createTeamsChat } = useContext(EscolaLMSContext);
  const [loading, setLoading] = useState(false);

  const onMsTeamsClick = useCallback(() => {
    setLoading(true);
    createTeamsChat(tutor.tutor.id)
      .then((response) => {
        if (response.success) {
          //@ts-ignore
          window.open(response.data.web_url, "_blank", "noopener noreferrer");
        }
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          toast.error("Nie znaleziono uczestnika czatu");
        } else {
          toast.error("Wystąpił nieoczekiwany błąd. Spróbuj ponownie");
        }

        console.error(error);
      })
      .finally(() => setLoading(false));
  }, [createTeamsChat, tutor.tutor.id]);

  return (
    <Card data-testid="card">
      <AvatarImage
        name={`${tutor?.tutor.first_name} ${tutor?.tutor.last_name}`}
        avatar={tutor?.tutor.path_avatar}
        size="md"
      />

      <Stack $gap={6}>
        <CardName>{`${tutor?.tutor.degree_name} ${tutor?.tutor.first_name} ${tutor?.tutor.last_name}`}</CardName>
        <CardSubject>
          {tutor?.subjects.map((subject) => subject.name)}
        </CardSubject>
        <CardPlace>{tutor?.tutor.organization_unit}</CardPlace>
      </Stack>
      <Button
        disabled={loading}
        title={loading ? "Ładowanie..." : "Microsoft Teams"}
        onClick={onMsTeamsClick}
      />
    </Card>
  );
};
