import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { styled } from "styled-components";
import { device } from "theme/breakpoints";

import React, { useContext, useEffect, useState } from "react";

import CertificateCard from "components/CertificateCard";
import Layout from "components/Layout";
import Pagination from "components/Pagination";
import { Row } from "components/Row";

const Column = styled.div`
  @media ${device.tablet} {
    max-width: calc(50% - 30px);
    flex: 0 0 calc(50% - 30px);
  }

  @media ${device.mobile} {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;

const CertificatesPage: React.FC = () => {
  const { certificates, fetchCertificates } = useContext(EscolaLMSContext);
  const [currentPage, setCurrentPage] = useState(1);
  const lastPage = certificates.list?.meta.last_page || 1;

  useEffect(() => {
    fetchCertificates({ page: currentPage, per_page: 15 });
  }, [currentPage, fetchCertificates]);
  return (
    <Layout title="Certyfikaty">
      <div data-testid="certificates-page">
        {certificates.list?.data.length === 0 && (
          <h4>Nie masz jeszcze żadnych certyfikatów</h4>
        )}
        <Row $flexWrap="wrap" $gap={30}>
          {certificates &&
            certificates?.list?.data &&
            certificates.list?.data.length > 0 &&
            certificates?.list?.data.map((certificate: API.Certificate) => (
              <Column key={certificate.id}>
                <CertificateCard certificate={certificate} />
              </Column>
            ))}
        </Row>
        <Pagination
          onPageClick={(page) => {
            setCurrentPage(page);
          }}
          lastPage={lastPage}
          currentPage={currentPage}
          onNextPage={() => setCurrentPage(currentPage + 1)}
          onPrevPage={() => setCurrentPage(currentPage - 1)}
        />
      </div>
    </Layout>
  );
};

export default CertificatesPage;
