import ReactMarkdown from "react-markdown";
import styled, { createGlobalStyle } from "styled-components";

export const StyledMarkdownRenderer = styled(ReactMarkdown)`
  & mark {
    background-color: #abff32;
  }
`;

export const StyledClickableWrapper = styled.div`
  display: inline-block;
`;

export const LightboxOverride = createGlobalStyle`

`;
