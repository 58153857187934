import { API } from "@escolalms/sdk/lib";

import React from "react";

import { TextArea } from "components/TextArea/TextArea";

import { getUniqueId } from "utils";

import DefaultQuestionLayout from "../DefaultQuestionLayout";

interface Props extends API.QuizQuestion_Essay {
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur: React.FocusEventHandler<HTMLTextAreaElement>;
  value: string;
  hasQuizEnded?: boolean;
  resultScore?: number | null;
}

const Essay: React.FC<Props> = ({
  id,
  title,
  question,
  onChange,
  onBlur,
  value,
  hasQuizEnded,
  resultScore,
}) => {
  return (
    <DefaultQuestionLayout
      data-testid={`essay-${question}`}
      title={title}
      question={question}
      resultScore={resultScore}
      showScore={hasQuizEnded}
    >
      <TextArea
        placeholder="Napisz odpowiedź"
        name={`${id}`}
        disabled={hasQuizEnded}
        id={getUniqueId(`Essay-${id}`)}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </DefaultQuestionLayout>
  );
};

export default Essay;
