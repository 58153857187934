import moment from "moment";
import "moment/locale/pl";
import { Calendar, Formats, View, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { EventCalendarProps } from "types/schedule.types";

import { FC, useEffect, useMemo, useState } from "react";

import useMediaQuery from "hooks/UseMediaQuery";

import { CalendarContainer } from "./styles";

type CalendarProps = {
  events: EventCalendarProps[];
  setClickedEvent: (event: EventCalendarProps) => void;
};

const messages = {
  today: "Dzisiaj",
  previous: "Poprzedni",
  next: "Następny",
  month: "Miesiąc",
  week: "Tydzień",
  day: "Dzień",
  agenda: "Agenda",
  date: "Data",
  time: "Czas",
  event: "Wydarzenie",
  noEventsInRange: "Brak wydarzeń",
};

export const CalendarComponent: FC<CalendarProps> = ({
  events,
  setClickedEvent,
}) => {
  const localizer = useMemo(() => momentLocalizer(moment), []);
  const [view, setView] = useState<View>("day");
  const mobileView = useMediaQuery("mobile");

  const createUpperCase = (text: string) =>
    text.replace(/(^|\s)\S/g, (match) => match.toLocaleUpperCase());

  const { formats }: { formats: Formats } = useMemo(
    () => ({
      formats: {
        weekdayFormat: (date, culture, localizer) =>
          String(localizer?.format(date, "dddd", culture)),
        monthHeaderFormat: (date, culture, localizer) =>
          createUpperCase(
            String(localizer?.format(date, "MMMM YYYY", culture))
          ),
        dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
          (createUpperCase(
            String(localizer?.format(start, "MMMM D", culture))
          ) +
            " - " +
            localizer?.format(end, "D", culture)) as string,
        dayHeaderFormat: (date, culture, localizer) =>
          createUpperCase(
            String(localizer?.format(date, "MMMM[,] dddd D", culture))
          ) as string,
      },
    }),
    []
  );

  useEffect(() => {
    setView("day");
  }, [mobileView]);

  return (
    <CalendarContainer data-testid="calendar">
      <Calendar
        localizer={localizer}
        events={events}
        onView={(currentView) => setView(currentView)}
        view={view}
        defaultView="week"
        startAccessor="start"
        endAccessor="end"
        formats={formats}
        scrollToTime={new Date()}
        onSelectEvent={setClickedEvent}
        messages={messages}
        showAllEvents
      />
    </CalendarContainer>
  );
};
