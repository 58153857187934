import React, { useId } from "react";

import Icon from "components/Icon";
import Loader from "components/Loader";

import { Input, Label, LabelContent, Wrapper } from "./styles";

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "id"> {
  className?: string;

  loading?: boolean;
  label?: string;
}

export const Upload: React.FC<Props> = ({
  className,
  loading,
  label = "Wgraj",
  ...rest
}) => {
  const id = useId();

  return (
    <Wrapper data-testid="upload-input" className={className}>
      <Input type="file" id={id} {...rest} />
      <Label htmlFor={id}>
        <LabelContent>
          {loading && <Loader />}
          {loading && <p>Ładowanie...</p>}
          {!loading && <Icon name="upload" />}
          {!loading && label && <p>{label}</p>}
        </LabelContent>
      </Label>
    </Wrapper>
  );
};
