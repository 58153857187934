import React from "react";

import Loader from "components/Loader";

import { LessonState, useLessonStateContext } from "../LessonStateContext";
import { CourseBeyondDeadline } from "./CourseBeyondDeadline";
import { CourseFinished } from "./CourseFinished";
import { CourseInProgress } from "./CourseInProgress";
import { LessonInactive } from "./LessonInactive";

const STATE_MAP: Record<LessonState, React.FC> = {
  init: Loader,
  "lesson-inactive": LessonInactive,
  "course-finished": CourseFinished,
  "course-in-progress": CourseInProgress,
  "course-beyond-deadline": CourseBeyondDeadline,
};

export const LessonStateContent: React.FC = () => {
  const { state } = useLessonStateContext();

  const StateComponent = STATE_MAP?.[state];

  return <StateComponent />;
};
