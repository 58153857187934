import React from "react";

import { AvatarImage } from "components/Avatar/AvatarImage";

import { AlbumNumber, Container, UserName } from "./styles";

type Props = {
  name: string;
  albumNumber?: string;
  avatar?: string;
  onClick?: () => void;
};

const UserInfo: React.FC<Props> = ({ name, albumNumber, avatar, onClick }) => (
  <Container onClick={onClick} data-testid="user-info">
    <AvatarImage name={name} avatar={avatar} />
    <div>
      <UserName>{name}</UserName>
      <AlbumNumber>{albumNumber}</AlbumNumber>
    </div>
  </Container>
);

export default UserInfo;
