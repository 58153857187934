import { useNavigate } from "react-router-dom";

import React from "react";

import { Button } from "components/Button";
import routeRoutes from "components/Routes/routes";
import { Row } from "components/Row";
import { Stack } from "components/Stack";

import { useLessonStateContext } from "../LessonStateContext";

export const CourseFinished: React.FC = () => {
  const navigate = useNavigate();
  const { changeLessonState } = useLessonStateContext();

  return (
    <Stack $gap={16} data-testid="course-finished">
      <h4>Ukończyłeś już ten kurs</h4>
      <Row $gap={10} $alignItems="center">
        <Button
          title="Moje kursy"
          variant="secondary"
          onClick={() => navigate(routeRoutes.eLearning)}
        />
        <Button
          title="Przejdź do lekcji"
          onClick={() => changeLessonState("course-in-progress")}
        />
      </Row>
    </Stack>
  );
};
