import React, { useCallback, useContext, useEffect, useState } from "react";

import { useCourse } from "./Context";

export type LessonState =
  | "init"
  | "course-in-progress"
  | "course-finished"
  | "lesson-inactive"
  | "course-beyond-deadline";

interface LessonStateContext {
  state: LessonState;
  changeLessonState: (state: LessonState) => void;
}

const Context = React.createContext<LessonStateContext>({
  state: "init",
  changeLessonState: () => console.warn("INIT"),
});

export const useLessonStateContext = () => useContext(Context);

export const LessonStateContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const {
    isCurrentLessonInactive,
    isCourseFinished,
    isBeyondProgressDeadline,
  } = useCourse();

  const [lessonState, setLessonState] = useState<LessonState>("init");

  useEffect(() => {
    // determine program mode
    if (isCurrentLessonInactive) {
      setLessonState("lesson-inactive");
    } else if (isBeyondProgressDeadline) {
      setLessonState("course-beyond-deadline");
    } else if (isCourseFinished) {
      setLessonState("course-finished");
    } else {
      setLessonState("course-in-progress");
    }
  }, [isCourseFinished, isCurrentLessonInactive]);

  const changeLessonState = useCallback(
    (state: LessonState) => setLessonState(state),
    []
  );

  return (
    <Context.Provider value={{ state: lessonState, changeLessonState }}>
      {children}
    </Context.Provider>
  );
};
