import { styled } from "styled-components";

import React from "react";

const StyledLoader = styled.div<{ $size?: number }>`
  width: ${({ $size }) => $size || 48}px;
  height: ${({ $size }) => $size || 48}px;
  border: 5px solid ${({ theme }) => theme.colors.white};
  border-bottom-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

const Loader: React.FC<{ size?: number }> = ({ size }) => {
  return <StyledLoader $size={size} data-testid="loader" />;
};

export default Loader;
