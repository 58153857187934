import { css, styled } from "styled-components";
import { device } from "theme/breakpoints";
import { getStyleBasedOnTheme } from "theme/utils";

export const Container = styled.header<{ $menuOpened: boolean }>`
  width: 100%;
  position: relative;
  padding: 19px 19px 19px 28px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};

  @media ${device.tablet} {
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 9px 13px;
  }

  &:after {
    display: ${({ $menuOpened }) => ($menuOpened ? "block" : "none")};
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    left: 0;
    top: 0;
  }
`;

export const Overlay = styled.div`
  display: block;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #00000080;
  left: 0;
  top: 0;
  z-index: 999;
`;

export const LeftSide = styled.div``;

export const HamburgerWrapper = styled.div<{ $isActive: boolean }>`
  position: relative;
  transition-delay: 0.3s;
  transition: all 0.3s ease-in-out;

  ${({ $isActive }) =>
    $isActive &&
    css`
      transition-delay: 0.3s;
      position: absolute;
      left: 28px;
      z-index: 1001;
    `}
`;

export const Logo = styled.div`
  width: 110px;
  height: 46px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: 2px;
`;

export const Subtitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;

  @media ${device.tablet} {
    padding-right: 21px;
    border-right: 1px solid #3a876c;
    margin-right: 21px;
  }
`;

export const UserInfoWrapper = styled.div`
  position: relative;
  margin: 0 38px 0 65px;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -21px;
    transform: translateY(-50%);
    width: 1px;
    height: calc(100% + 38px);
    background-color: ${({ theme }) => theme.colors.border};
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -21px;
    transform: translateY(-50%);
    width: 1px;
    height: calc(100% + 38px);
    background-color: ${({ theme }) => theme.colors.border};
  }
`;

export const Button = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textPrimary};

  @media ${device.tablet} {
    svg {
      color: ${({ theme }) =>
        getStyleBasedOnTheme(theme, {
          base: theme.colors.white,
          contrast: theme.colors.black,
        })};
    }
  }
`;

export const MobileSchoolInfo = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: 16px;
`;
