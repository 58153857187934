import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";

import { useContext, useEffect } from "react";

import Layout from "components/Layout";
import Loader, { LoaderContainer } from "components/Loader";
import Pagination from "components/Pagination";
import { SchoolContext } from "components/SchoolContext";
import SubjectCollapse from "components/SubjectCollapse";

import useMediaQuery from "hooks/UseMediaQuery";

const SubjectsPage: React.FC = () => {
  const { fetchSubjects, subjects } = useContext(EscolaLMSContext);
  const tabletView = useMediaQuery("tablet");
  const { semesterId } = useContext(SchoolContext);

  useEffect(() => {
    if (semesterId) {
      fetchSubjects({
        semester_id: semesterId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semesterId]);

  return (
    <Layout title="Przedmioty">
      <div
        data-testid="subjects-container"
        style={{
          paddingTop: 20,
        }}
      >
        {subjects?.list?.data?.length === 0 ? (
          <div>Brak przedmiotów dla wybranego semestru</div>
        ) : subjects.loading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          subjects?.list?.data?.map((subject, index) => (
            <SubjectCollapse
              showLabels={!tabletView && index === 0}
              key={index}
              subject={subject}
            />
          ))
        )}
        {subjects.list?.data?.length! > 0 && (
          <Pagination
            onPageClick={(page) => {
              fetchSubjects({
                semester_id: Number(semesterId),
                page,
              });
            }}
            lastPage={subjects.list?.meta.last_page || 0}
            currentPage={subjects.list?.meta.current_page || 0}
            onNextPage={() => {
              fetchSubjects({
                semester_id: Number(semesterId),
                page: subjects.list?.meta?.current_page! + 1,
              });
            }}
            onPrevPage={() => {
              fetchSubjects({
                semester_id: Number(semesterId),
                page: subjects.list?.meta?.current_page! - 1,
              });
            }}
          />
        )}
      </div>
    </Layout>
  );
};

export default SubjectsPage;
