import { API } from "@escolalms/sdk/lib";

export type Tutor = {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
};

export type Subject = {
  id: number;
  name: string;
};

export type Semester = {
  id: number;
  name: string;
  type: string;
  year: string;
};

export type Group = {
  id: number;
  name: string;
};

export type TermStatus = {
  id: number;
  name: string;
};

export interface ExtendableStyledComponent {
  className?: string;
}

export interface CourseAgendaSharedComponentPropsHandlers {
  onTopicClick: (topic: API.Topic) => void;
  onNextTopicClick: () => void;
}

export interface CourseAgendaSharedComponentProps
  extends CourseAgendaSharedComponentPropsHandlers {
  mobile?: boolean;
}

export enum ExamGradeType {
  Manual = "manual",
  ManualPass = "manual_pass",
  ManualGrades = "manual_grades",
}
