import { format } from "date-fns";
import { Link } from "react-router-dom";
import { EventCalendarProps } from "types/schedule.types";

import { FC } from "react";

import { Button } from "components/Button";

import { useOutsideClick } from "hooks/useOutsideClick";

import { TIME_FORMAT } from "../../consts/dates";
import {
  CloseButton,
  ModalContent,
  ModalHeading,
  ModalOverlay,
  SubjectInfo,
  UserInfo,
} from "./styles";

type EventModalProps = {
  event: EventCalendarProps;
  isOpen: boolean;
  onClose: () => void;
};

export const EventModal: FC<EventModalProps> = ({ event, isOpen, onClose }) => {
  const { elementRef } = useOutsideClick(onClose);
  if (!isOpen) {
    return null;
  }

  return (
    <ModalOverlay>
      <ModalContent ref={elementRef} data-testid="event-modal">
        <ModalHeading>
          <h2>{event.title}</h2>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeading>
        <SubjectInfo $gap={8}>
          <h4>{event.subject}</h4>
          <p>{event.semester}</p>
          <p>
            {`Godzina: ${format(new Date(event.start), TIME_FORMAT)} - ${format(
              new Date(event.end),
              TIME_FORMAT
            )}`}
          </p>
          <p>Sala: {event.class_name_abbreviation}</p>
        </SubjectInfo>
        {event.tutor_name && event.tutor_email && (
          <UserInfo $gap={8}>
            <h4>Prowadzący: {event.tutor_name}</h4>
            <p>{event.tutor_email}</p>
          </UserInfo>
        )}
        {event.teams_url && (
          <Link to={event.teams_url} target="_blank" rel="noopener noreferrer">
            <Button title="Microsoft Teams" />
          </Link>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};
