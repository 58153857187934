import styled from "styled-components";
import { device } from "theme/breakpoints";

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  @media ${device.tablet} {
    justify-content: center;
  }
`;
