import { API } from "@escolalms/sdk/lib";

import React from "react";

import { getUniqueId } from "utils";

import { StyledDefaultLayout } from "../..";

interface Props extends API.QuizQuestion_ShortAnswers {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  value: string;
  hasQuizEnded?: boolean;
  resultScore?: number | null;
}

const ShortAnswers: React.FC<Props> = ({
  question,
  title,
  id,
  onChange,
  onBlur,
  value,
  hasQuizEnded,
  resultScore,
}) => (
  <StyledDefaultLayout
    data-testid={`short-answers-${question}`}
    title={title}
    question={question}
    resultScore={resultScore}
    showScore={hasQuizEnded}
  >
    <input
      placeholder="Napisz odpowiedź"
      id={getUniqueId(`ShortAnswers-${id}`)}
      name={`${id}`}
      disabled={hasQuizEnded}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  </StyledDefaultLayout>
);

export default ShortAnswers;
