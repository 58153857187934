import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { Link, useNavigate } from "react-router-dom";
import { useAccessibilityControls } from "theme/ThemeWrapper";

import React, { useContext, useState } from "react";

import Hamburger from "components/Hamburger";
import Icon from "components/Icon";
import MobileMenu from "components/MobileMenu";
import { MyAccountModal } from "components/MyAccountModal";
import routeRoutes from "components/Routes/routes";
import SemestersSelect from "components/SemestersSelect";
import UserInfo from "components/UserInfo";

import useMediaQuery from "hooks/UseMediaQuery";

import {
  Button,
  Container,
  HamburgerWrapper,
  LeftSide,
  Logo,
  MobileSchoolInfo,
  Overlay,
  RightSide,
  SettingsContainer, // Subtitle,
  Title,
  UserInfoWrapper,
} from "./styles";

type SchoolInfoProps = {
  title: string;
};

const SchoolInfo: React.FC<SchoolInfoProps> = ({ title }) => {
  // const handleAcademicYearChange = useCallback(
  //   async (event: React.ChangeEvent<HTMLSelectElement>) => {
  //     const academicYearId = Number(event.target.value);
  //     setAcademicYearId(academicYearId);

  //     const res = await fetchSemesters();

  //     if (res?.success && Array.isArray(res.data)) {
  //       const activeSemesters = res.data.filter((item) => item.is_active);
  //       if (activeSemesters.length === 0) {
  //         setSemesterId(null);
  //       } else {
  //         setSemesterId(activeSemesters[0]?.id);
  //       }
  //     }
  //   },
  //   [fetchSemesters, setAcademicYearId, setSemesterId]
  // );

  return (
    <>
      {/* <Select
        defaultValue={academicYearId ? String(academicYearId) : "0"}
        sufix
        color={theme?.colors.primary}
        variant="noBorder"
        id="academic-year"
        name="academic-year"
        onChange={(e) => handleAcademicYearChange(e)}
        options={
          academicYears?.list?.map((item) => ({
            label: `${item.name} (${item.year_name})`,
            value: String(item.id),
          })) || []
        }
      /> */}
      <Title>{title}</Title>
      <SemestersSelect />
    </>
  );
};

const Header = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { user, logout } = useContext(EscolaLMSContext);
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [showMyAccountModal, setShowMyAccountModal] = useState(false);
  const { toggleTheme, toggleFontSize } = useAccessibilityControls();
  const navigate = useNavigate();
  const tabletView = useMediaQuery("tablet");
  const userSchoolInfo = user.value?.membership_info?.[0];

  return (
    <>
      <Container $menuOpened={menuIsVisible} ref={ref} data-testid="header">
        <LeftSide>
          {tabletView ? (
            <Link to={routeRoutes.subjects}>
              <Logo>
                <Icon name="logoTablet" />
              </Logo>
            </Link>
          ) : (
            userSchoolInfo?.course && (
              <SchoolInfo
                title={`Kierunek ${userSchoolInfo?.course.value} / ${userSchoolInfo?.kind?.value} ${userSchoolInfo?.type?.value}`}
              />
            )
          )}
        </LeftSide>
        <RightSide>
          <SettingsContainer>
            <Button title="Zmień kontrast" onClick={toggleTheme}>
              <Icon name="contrast" />
            </Button>
            <Button title="Zmień rozmiar czcionki" onClick={toggleFontSize}>
              <Icon name="fontSettings" />
            </Button>
          </SettingsContainer>
          {!tabletView ? (
            <>
              {user.value && (
                <UserInfoWrapper>
                  <UserInfo
                    name={user.value.name}
                    albumNumber={user?.value?.index_no?.[0] || ""}
                    avatar={user.value.avatar}
                    onClick={() => setShowMyAccountModal(true)}
                    data-testid="user-info"
                  />
                </UserInfoWrapper>
              )}
              <Button
                title="Wyloguj"
                onClick={() =>
                  logout().then(() => {
                    navigate(routeRoutes.login);
                  })
                }
              >
                <Icon name="logout" />
              </Button>
            </>
          ) : (
            <HamburgerWrapper $isActive={menuIsVisible}>
              <Hamburger onClick={() => setMenuIsVisible(!menuIsVisible)} />
            </HamburgerWrapper>
          )}
        </RightSide>
        <MobileMenu visible={menuIsVisible && tabletView} />
        {menuIsVisible && tabletView && <Overlay />}
      </Container>
      {tabletView && userSchoolInfo?.course && (
        <MobileSchoolInfo>
          <SchoolInfo
            title={`Kierunek ${userSchoolInfo?.course.value} / ${userSchoolInfo?.kind?.value} ${userSchoolInfo?.type?.value}`}
          />
        </MobileSchoolInfo>
      )}
      {user.value && (
        <MyAccountModal
          isOpen={showMyAccountModal}
          onClose={() => setShowMyAccountModal(false)}
          user={user.value}
        />
      )}
    </>
  );
});

export default Header;
