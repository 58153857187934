import { API } from "@escolalms/sdk/lib";

import React from "react";

import Icon from "components/Icon";

import useMediaQuery from "hooks/UseMediaQuery";

import {
  CollapseButton,
  Container,
  Header,
  HeaderInfoText,
  HeaderTextContainer,
  Label,
  TableHeader,
  TableHeaderItem,
  Title,
} from "./styles";

type Props = {
  subject: API.GroupSubject;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  showLabels?: boolean;
};

const tableHeaderItems: string[] = [
  "Nazwa przedmiotu",
  "Typ zajęć",
  "Godziny",
  "Prowadzący",
  "Forma zaliczenia",
];

const CollapseHeader: React.FC<Props> = ({
  subject,
  isOpen,
  setIsOpen,
  showLabels,
}) => {
  const tabletView = useMediaQuery("tablet");
  return (
    <Container>
      {showLabels && (
        <TableHeader>
          {tableHeaderItems.map((item, index) => (
            <TableHeaderItem key={index}>{item}</TableHeaderItem>
          ))}
        </TableHeader>
      )}
      <Header
        data-testid="collapse-header"
        aria-hidden
        onClick={() => setIsOpen(!isOpen)}
      >
        <HeaderTextContainer>
          <Title>{subject.subject.name}</Title>
          <HeaderInfoText>{subject.as_form_name}</HeaderInfoText>
          <HeaderInfoText>
            {subject.ssubject_form_hours_numbers} godz.
          </HeaderInfoText>
          <HeaderInfoText>
            {subject.tutor.first_name} {subject.tutor.last_name}
          </HeaderInfoText>
          <HeaderInfoText>
            {tabletView ? <Label>Forma zaliczenia</Label> : ""}{" "}
            {subject.as_assessment_form_name}
          </HeaderInfoText>
        </HeaderTextContainer>
        <CollapseButton $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <Icon name="arrowDown" />
        </CollapseButton>
      </Header>
    </Container>
  );
};

export default CollapseHeader;
