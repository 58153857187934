import { styled } from "styled-components";
import { getStyleBasedOnTheme } from "theme/utils";

import React, { useState } from "react";

type Props = {
  onClick: () => void;
};

const HamburgerButton = styled.button`
  width: 21px;
  height: 21px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  z-index: 1000;
  position: relative;
  transition: all 0.3s ease-in-out;
  transform: rotate(0deg);
  transform-origin: center;
  span {
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) =>
      getStyleBasedOnTheme(theme, {
        base: theme.colors.white,
        contrast: theme.colors.black,
      })};
  }
`;

const Hamburger: React.FC<Props> = ({ onClick }) => {
  const [active, setActive] = useState(false);
  return (
    <HamburgerButton
      data-testid="hamburger"
      onClick={() => {
        setActive(!active);
        onClick();
      }}
    >
      {Array.from({ length: 3 }).map((_, index) => (
        <span key={index} />
      ))}
    </HamburgerButton>
  );
};

export default Hamburger;
