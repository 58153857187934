import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import moment from "moment";
import { EventCalendarProps } from "types/schedule.types";

import { FC, useContext, useEffect, useMemo, useState } from "react";

import { CalendarComponent } from "components/Calendar";
import { EventModal } from "components/EventModal";
import Layout from "components/Layout";
import Loader, { LoaderContainer } from "components/Loader";

const SchedulePage: FC = () => {
  const { fetchSchedule, schedule } = useContext(EscolaLMSContext);

  const [selectedEvent, setSelectedEvent] = useState<EventCalendarProps | null>(
    null
  );
  const [showModal, setShowModal] = useState(false);

  const handleEventClick = (event: EventCalendarProps) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setShowModal(false);
  };

  const events: EventCalendarProps[] = useMemo(
    () =>
      (schedule.list ?? []).map((event) => ({
        id: event.id,
        title: `${event.subject.name} (${event.group.name} - ${event.term_status.name})`,
        start: moment(event.date_from).toDate(),
        end: moment(event.date_to).toDate(),
        tutor_name:
          event.tutor && `${event.tutor.first_name} ${event.tutor.last_name}`,
        tutor_email: event.tutor && event.tutor.email,
        semester: event.semester.name,
        group: event.group.name,
        subject: event.subject.name,
        teams_url: event.ms_teams_join_url,
        //@ts-ignore TODO: remove after sdk update
        class_name_abbreviation: event.class_name_abbreviation,
      })),
    [schedule]
  );

  useEffect(() => {
    fetchSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div data-testid="schedule-page">
        {schedule.loading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <CalendarComponent
            events={events}
            setClickedEvent={handleEventClick}
          />
        )}
        {selectedEvent && (
          <EventModal
            event={selectedEvent}
            isOpen={showModal}
            onClose={closeModal}
          />
        )}
      </div>
    </Layout>
  );
};

export default SchedulePage;
