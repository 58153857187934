import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { format } from "date-fns";
import { toast } from "react-toastify";

import React, { useCallback, useContext, useState } from "react";

import { Button } from "components/Button";

import { DATETIME_FORMAT } from "../../consts/dates";
import { Container, DateWrapper, Title } from "./styles";

type Props = {
  certificate: API.Certificate;
};

const CertificateCard: React.FC<Props> = ({ certificate }) => {
  const { generateCertificate } = useContext(EscolaLMSContext);
  const [loadingId, setLoadingId] = useState<number>(-1);

  const downloadCertificate = useCallback(
    async (id: number, title?: string) => {
      setLoadingId(id);
      try {
        const response = await generateCertificate(id);
        if (response) {
          // create hidden link
          const element = document.createElement("a");
          document.body.appendChild(element);
          element.setAttribute(
            "href",
            URL.createObjectURL(new Blob([response]))
          );
          element.setAttribute("download", `${title || "Certificate"}.pdf`);
          element.style.display = "";
          element.click();
          document.body.removeChild(element);
          setLoadingId(-1);
        }
      } catch (error) {
        toast.error("Wystąpił nieoczekiwany błąd. Spróbuj ponownie");
        setLoadingId(-1);
        console.log(error);
      }
    },
    [generateCertificate]
  );

  return (
    <Container data-testid="certificate-card">
      <div>
        <Title>{certificate.title}</Title>
        <DateWrapper>
          Otrzymany w dn.{" "}
          {format(new Date(certificate.created_at), DATETIME_FORMAT)}
        </DateWrapper>
      </div>
      <Button
        data-testid="certificate-download-btn"
        loading={loadingId === certificate.id}
        title={loadingId === certificate.id ? "Pobieranie..." : "Pobierz"}
        variant="secondary"
        icon="file"
        iconGap={40}
        onClick={() => downloadCertificate(certificate.id, certificate.title)}
      />
    </Container>
  );
};

export default CertificateCard;
