import baseTheme from "./base";
import contrastTheme from "./contrast";
import type { SharedThemeValues } from "./shared";

export type Theme = SharedThemeValues & {
  name: "base" | "contrast";
  fontSize: "normal" | "big";
  colors: {
    primary: string;
    secondary: string;
    black: string;
    gray: string;
    white: string;
    primaryBackground: string;
    secondaryBackground: string;
    primaryButton: string;
    border: string;
    green: string;
    red: string;
    textPrimary: string;
    textSecondary: string;
    textAccent: string;
    error: string;
  };
  shadows: {
    primary: string;
  };
};

const themes: Record<Theme["name"], Theme> = {
  base: baseTheme,
  contrast: contrastTheme,
};

export default themes;
