import { useLocalStorageState } from "hooks";
import { ThemeProvider } from "styled-components";
import themes from "theme";

import React, { useCallback, useContext } from "react";

import sharedThemeValues, { bigFontSizes } from "./shared";

type ThemeWrapperProps = {
  children: React.ReactNode;
};

type AccessibilityControlsContextProps = {
  toggleTheme: () => void;
  toggleFontSize: () => void;
};

const AccessibilityControlsContext =
  React.createContext<AccessibilityControlsContextProps>({
    toggleTheme: () => console.warn("default value"),
    toggleFontSize: () => console.warn("default value"),
  });

export const useAccessibilityControls = () =>
  useContext(AccessibilityControlsContext);

const ThemeWrapper: React.FC<ThemeWrapperProps> = ({ children }) => {
  const [theme, setTheme] = useLocalStorageState("awf", "theme", themes.base);

  const toggleTheme = useCallback(() => {
    setTheme((prev) =>
      prev.name === "base"
        ? {
            ...themes.contrast,
            fontSize: prev.fontSize,
            fontSizes: prev.fontSizes,
          }
        : { ...themes.base, fontSize: prev.fontSize, fontSizes: prev.fontSizes }
    );
  }, [setTheme]);

  const toggleFontSize = useCallback(() => {
    setTheme((prev) => ({
      ...prev,
      fontSize: prev.fontSize === "normal" ? "big" : "normal",
      fontSizes:
        prev.fontSize === "normal" ? bigFontSizes : sharedThemeValues.fontSizes,
    }));
  }, [setTheme]);

  return (
    <AccessibilityControlsContext.Provider
      value={{ toggleTheme, toggleFontSize }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AccessibilityControlsContext.Provider>
  );
};

export default ThemeWrapper;
