import styled from "styled-components";

type InputType = {
  $inputError: boolean;
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10vh;
  padding: 13px;
  align-items: center;
  height: 100vh;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 0px 200px 0px 0px;
  & > picture {
    margin-top: 10vh;
    aspect-ratio: 0;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 454px;
  width: 100%;
  min-height: 300px;
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 15px #00000040;
  border-radius: 3px;
  & form {
    display: flex;
    flex-direction: column;
    gap: 14px;

    button {
      margin: 0 0 0 auto;
      display: inline-block;
    }
  }
`;

export const Heading = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  margin-bottom: 16px;
`;

export const Input = styled.div<InputType>`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 6px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  & input {
    height: 42px;
    padding: 10px;
    background: ${({ theme }) => theme.colors.primaryBackground};
    border: 1px solid
      ${({ theme, $inputError }) =>
        $inputError ? theme.colors.error : theme.colors.border};
    border-radius: 4px;
    opacity: 1;
    outline: none;
    &:focus-visible {
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }
  }
  & > picture {
    aspect-ratio: 0;
    position: absolute;
    right: 24px;
    top: 36px;
    cursor: pointer;
  }
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error};
`;
