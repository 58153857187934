import styled, { css } from "styled-components";
import type { DefaultTheme } from "styled-components";
import { getStyleBasedOnTheme } from "theme/utils";

export type ButtonVariant = "primary" | "secondary";

type ButtonProps = {
  $variant?: ButtonVariant;
  $loading?: boolean;
};

const getButtonStyles = (theme: DefaultTheme, variant: ButtonVariant) => {
  switch (variant) {
    case "primary":
      return css`
        max-width: fit-content;
        background: ${theme.colors.primaryButton};
        color: ${getStyleBasedOnTheme(theme, {
          base: theme.colors.white,
          contrast: theme.colors.black,
        })};
      `;
    case "secondary":
      return css`
        max-width: fit-content;
        background: ${theme.colors.secondary};
        color: ${getStyleBasedOnTheme(theme, {
          base: theme.colors.white,
          contrast: theme.colors.black,
        })};
      `;
    default:
      return css`
        background: ${theme.colors.primaryButton};
        color: ${getStyleBasedOnTheme(theme, {
          base: theme.colors.white,
          contrast: theme.colors.black,
        })};
      `;
  }
};
export const StyledButton = styled.button<ButtonProps>`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  ${({ theme, $variant }) => getButtonStyles(theme, $variant ?? "primary")}

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
