import React from "react";

import {
  Container,
  Percentage,
  Progress,
  ProgressBarContainer,
  Text,
  TextContainer,
} from "./styles";

type Props = {
  progress: number;
  maxProgress?: number;
};

const ProgressBar: React.FC<Props> = ({ progress, maxProgress = 100 }) => (
  <Container data-testid="progress-bar">
    <TextContainer>
      <Text>Ukończono</Text>
      <Percentage>{progress}%</Percentage>
    </TextContainer>
    <ProgressBarContainer>
      <Progress progress={(progress / maxProgress) * 100} />
    </ProgressBarContainer>
  </Container>
);

export default ProgressBar;
