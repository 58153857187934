import { API } from "@escolalms/sdk/lib";

import { FC, useContext } from "react";

import Avatar from "components/Avatar";
import { SchoolContext } from "components/SchoolContext";

import { useOutsideClick } from "hooks/useOutsideClick";

import {
  CloseButton,
  InfoContainer,
  ModalContent,
  ModalHeading,
  ModalOverlay,
  SchoolInfo,
  UserInfo,
} from "./styles";

type MyAccountMoadlProps = {
  user: API.UserAsProfile;
  isOpen: boolean;
  onClose: () => void;
};

export const MyAccountModal: FC<MyAccountMoadlProps> = ({
  user,
  isOpen,
  onClose,
}) => {
  const { elementRef } = useOutsideClick(onClose);
  const userSchoolInfo = user?.membership_info?.[0];

  const { selectedSemester } = useContext(SchoolContext);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalOverlay data-testid="myAccount-modal">
      <ModalContent ref={elementRef}>
        <ModalHeading>
          <h2>Moje konto</h2>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeading>
        <Avatar name={user.name} avatar={user.avatar} />
        <InfoContainer $gap={23}>
          <UserInfo $gap={8}>
            <h4>{user.name}</h4>
            {user.index_no && <p>Numer albumu {user.index_no[0]}</p>}
            <p>{user.email}</p>
          </UserInfo>
          {userSchoolInfo?.course && (
            <SchoolInfo $gap={8}>
              <h4>{`Kierunek ${userSchoolInfo?.course.value} / ${userSchoolInfo?.kind?.value} ${userSchoolInfo?.type?.value}`}</h4>
              <p>{`${selectedSemester?.name} (spec: ${selectedSemester?.speciality})`}</p>
            </SchoolInfo>
          )}
          {/* //TODO: uncomment when we get this data from API */}
          {/* <UserInfo $gap={8}>
            <p>Opiekun roku</p>
            <h4>mgr Jerzy Kłosiński</h4>
          </UserInfo> */}
        </InfoContainer>
      </ModalContent>
    </ModalOverlay>
  );
};
