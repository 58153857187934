import { css, styled } from "styled-components";
import { device } from "theme/breakpoints";
import { getStyleBasedOnTheme } from "theme/utils";

export const flexStyles = css`
  &:nth-of-type(1) {
    flex: 0 0 25%;
    max-width: 25%;

    @media ${device.tablet} {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &:nth-of-type(2) {
    flex: 0 0 25%;
    max-width: 25%;

    @media ${device.tablet} {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &:nth-of-type(3) {
    flex: 0 0 16.5%;
    max-width: 16.5%;

    @media ${device.tablet} {
      text-align: right;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &:nth-of-type(4) {
    flex: 0 0 28%;
    max-width: 28%;

    @media ${device.tablet} {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &:nth-of-type(5) {
    flex: 0 0 16.5%;
    max-width: 16.5%;

    @media ${device.tablet} {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 0;
    }
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  position: relative;
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 10px;

  @media ${device.tablet} {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 6px;
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-transform: uppercase;
  white-space: nowrap;
`;

export const HeaderInfoText = styled.div`
  &::first-letter {
    text-transform: uppercase;
  }
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  padding-right: 10px;
  ${flexStyles}

  @media ${device.tablet} {
    margin-bottom: 6px;
    padding-right: 0;
  }
`;

export const HeaderTextContainer = styled.div`
  padding: 20px 90px 20px 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media ${device.tablet} {
    flex-wrap: wrap;
    padding: 12px 37px 12px 12px;
  }
`;

export const CollapseButton = styled.button<{ $isOpen: boolean }>`
  border: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  display: block;
  height: 100%;
  padding: 0 6px;
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.textPrimary};

  svg {
    transition: transform 0.2s ease-in-out;
    transform: ${({ $isOpen }) =>
      $isOpen ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  top: -20px;
  padding: 0 90px 0 20px;
  width: 100%;
`;

export const TableHeaderItem = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) =>
    getStyleBasedOnTheme(theme, {
      base: theme.colors.gray,
      contrast: theme.colors.primary,
    })};
  text-transform: uppercase;
  ${flexStyles}
`;
