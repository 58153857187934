import { API } from "@escolalms/sdk/lib";
import { isBefore } from "date-fns";

import React from "react";

import Icon from "components/Icon";
import ProgressBar from "components/ProgressBar";

import { onKeyboardInteraction } from "utils/a11y";

import placeholder from "./placeholder.png";
import {
  Card,
  CardButton,
  CardImage,
  CardImageWrapper,
  Content,
  DisabledIcon,
  DisabledText,
  Teacher,
  Title,
} from "./styles";

const checkIsActive = (date?: Date | string | null) => {
  if (!date) return true;

  const today = new Date();
  const activeTo = new Date(date);

  return isBefore(today, activeTo);
};

type Props = {
  course: API.Course;
  onClick: () => void;
  progress: number;
};
const CourseCard: React.FC<Props> = ({ course, onClick, progress }) => {
  const isCourseActive = checkIsActive(course.active_to);

  return (
    <Card
      disabled={!isCourseActive}
      onClick={isCourseActive ? onClick : undefined}
      role="button"
      aria-disabled={!isCourseActive}
      tabIndex={isCourseActive ? 0 : -1}
      onKeyDown={isCourseActive ? onKeyboardInteraction(onClick) : undefined}
      data-testid="course-card"
    >
      <CardButton>
        <CardImageWrapper>
          <CardImage src={course.image_url || placeholder} alt={course.title} />
          {!isCourseActive && (
            <DisabledIcon>
              <Icon name="lock" />
            </DisabledIcon>
          )}
        </CardImageWrapper>
        <Content>
          <Title>{course.title}</Title>

          {course.authors?.[0] && (
            <Teacher>{`${course.authors?.[0]?.first_name} ${course.authors?.[0]?.last_name}`}</Teacher>
          )}

          {isCourseActive ? (
            <>{progress > 0 && <ProgressBar progress={progress} />}</>
          ) : (
            <DisabledText>Tymczasowo nieaktywne</DisabledText>
          )}
        </Content>
      </CardButton>
    </Card>
  );
};

export default CourseCard;
