import { API } from "@escolalms/sdk/lib/index";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import {
  quizAttempt as fetchQuizAttempt,
  quizAnswer,
  quizAttemptFinish,
} from "@escolalms/sdk/lib/services/gfit_quiz";
import styled from "styled-components";
import { GiftQuizAnswer } from "types/gift-quiz";

import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button } from "components/Button";
import Loader from "components/Loader";

import { NormalizedError, normalizeError } from "utils/sdk";

import GiftQuizPlayerContent from "./GiftQuizPlayerContent";
import DefaultQuestionLayout from "./questions/DefaultQuestionLayout";

interface Props extends EventHandlers {
  topic: API.TopicQuiz;
}
interface EventHandlers {
  onTopicEnd?: () => void;
  onQuizStart?: (data: QuizDataValue) => void;
  onQuizStartError?: (error: NormalizedError) => void;
}

type QuizDataValue = API.QuizAttempt & {
  is_ended?: boolean;
};

interface QuizData {
  loading: boolean;
  value?: QuizDataValue;
  error?: API.DefaultResponseError;
}

const Wrapper = styled.div`
  & > .spinner__overlay {
    min-height: 529px;
  }
`;

const StartButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;
export const StyledDefaultLayout = styled(DefaultQuestionLayout)`
  & input {
    height: 42px;
    padding: 10px;
    background: ${({ theme }) => theme.colors.primaryBackground};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 4px;
    opacity: 1;
    outline: none;
    &:focus-visible {
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }
  }
  & > picture {
    aspect-ratio: 0;
    position: absolute;
    right: 24px;
    top: 36px;
    cursor: pointer;
  }
`;

function useQuiz(
  quizId: number | undefined,
  { onTopicEnd, onQuizStart, onQuizStartError }: EventHandlers
) {
  const [data, setData] = useState<QuizData>({ loading: false });
  const { token, apiUrl } = useContext(EscolaLMSContext);

  const topicEndCb = useRef(onTopicEnd);
  const quizStartCb = useRef(onQuizStart);
  const quizStartErrorCb = useRef(onQuizStartError);

  const startQuiz = useCallback(() => {
    if (quizId && token) {
      setData((prev) => ({ ...prev, loading: true }));
      fetchQuizAttempt(apiUrl, token, {
        topic_gift_quiz_id: quizId,
      })
        .then((response) => {
          if (response.success) {
            setData((prev) => ({ ...prev, value: response.data }));
            quizStartCb.current?.(response.data);
          } else {
            setData((prev) => ({ ...prev, error: response }));
            quizStartErrorCb.current?.(normalizeError(response));
          }
        })
        .catch((error: API.DefaultResponseError) => {
          setData((prev) => ({ ...prev, error }));
          quizStartErrorCb.current?.(normalizeError(error));
        })
        .finally(() => {
          setData((prev) => ({ ...prev, loading: false }));
        });
    }
  }, [quizId, apiUrl, token]);

  const endQuiz = useCallback(
    (quizAttemptId: number) => {
      if (token) {
        quizAttemptFinish(apiUrl, token, quizAttemptId).then((response) => {
          if (response.success) {
            setData((prev) => ({ ...prev, value: response.data }));
            topicEndCb.current?.();
          }
        });
      }
    },
    [token, apiUrl]
  );

  const sendAnswer = useCallback(
    <Answer extends GiftQuizAnswer>(questionId: number, answer: Answer) => {
      if (data?.value?.id && token && !data?.value?.is_ended) {
        quizAnswer(apiUrl, token, {
          topic_gift_question_id: questionId,
          topic_gift_quiz_attempt_id: data.value.id,
          answer,
        });
      }
    },
    [token, apiUrl, data?.value?.id, data?.value?.is_ended]
  );

  const getQuestionAnswerObj = useCallback(
    (questionId: number) =>
      data.value?.answers?.find(
        (
          answerItem: API.QuizAttempt & {
            is_ended?: boolean;
          }
        ) => answerItem?.topic_gift_question_id === questionId
      ),
    [data.value?.answers]
  );

  return useMemo(
    () => ({
      data,
      startQuiz,
      sendAnswer,
      getQuestionAnswerObj,
      endQuiz,
    }),
    [data, sendAnswer, getQuestionAnswerObj, startQuiz, endQuiz]
  );
}

const GiftQuizPlayer: React.FC<Props> = ({
  topic,
  onTopicEnd,
  onQuizStartError,
  onQuizStart,
}) => {
  const { data, startQuiz, sendAnswer, endQuiz } = useQuiz(topic.topicable.id, {
    onTopicEnd,
    onQuizStartError,
    onQuizStart,
  });

  return (
    <Wrapper data-testid="gift-quiz-player">
      {!data.value && !data.loading && (
        <StartButtonWrapper>
          <Button
            title="Start"
            variant="secondary"
            type="button"
            onClick={startQuiz}
          />
        </StartButtonWrapper>
      )}
      {data.loading && !data.value && <Loader />}
      {data.value && (
        <GiftQuizPlayerContent
          attempt={data.value}
          startQuiz={startQuiz}
          sendAnswer={sendAnswer}
          endQuiz={endQuiz}
        />
      )}
    </Wrapper>
  );
};

export default GiftQuizPlayer;
