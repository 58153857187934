import React from "react";

import logotypes from "./logos.png";
import { StyledFooter } from "./styles";

const Footer = React.forwardRef<HTMLDivElement>((props, ref) => (
  <StyledFooter ref={ref} data-testid="footer">
    <img src={logotypes} alt="" />
  </StyledFooter>
));

export default Footer;
