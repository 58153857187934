import styled from "styled-components";
import { device } from "theme/breakpoints";

export const Wrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  position: absolute;
  inset: 0;
  opacity: 0;
  pointer-events: none;
`;

export const LabelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.textPrimary};

  transition: transform 0.3s ease-in-out;
`;

export const Label = styled.label`
  display: grid;
  place-items: center;

  width: 100%;
  aspect-ratio: 16/3;

  border: 1px solid ${({ theme }) => theme.colors.border};

  user-select: none;

  input:not(:disabled) + & {
    cursor: pointer;

    &:hover ${LabelContent} {
      transform: scale(1.2);
    }
  }

  input:focus-visible + & {
    outline: -webkit-focus-ring-color auto 1px;
  }

  @media (${device.mobile}) {
    aspect-ratio: 16/9;
  }
`;
