import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LoginFormData } from "types/login.types";

import { FC, useContext, useState } from "react";

import { Button } from "components/Button";
import Footer from "components/Footer";
import Icon from "components/Icon";
import routeRoutes from "components/Routes/routes";

import { ErrorMessage, FormWrapper, Heading, Input, Wrapper } from "./styles";

const LoginPage: FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useContext(EscolaLMSContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<LoginFormData>();

  const navigate = useNavigate();

  const onSuccess = () => {
    navigate(routeRoutes.schedule);
  };

  const onError = () => {
    const errorFields = ["email", "password"];

    errorFields.forEach((fieldName) => {
      setError(fieldName as "email" | "password", {
        type: "error",
        message: "Błędny login lub hasło",
      });
    });
  };

  const onSubmit = ({ email, password }: LoginFormData) => {
    // TODO: Temporary refresh fix!
    login({ email, password, remember_me: 1 }).then((response) => {
      if (response.success) {
        onSuccess();
      } else {
        onError();
      }
    });
  };
  return (
    <Wrapper data-testid="login-page">
      <Icon name="logoHorizontal" />
      <FormWrapper>
        <Heading>Panel logowania</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input $inputError={!!errors.email}>
            <label htmlFor="email">Email / login</label>
            <input
              id="email"
              type="text"
              placeholder="Adres e-mail / login"
              data-testid="email"
              {...register("email", {
                required: { value: true, message: "Pole jest wymagane" },
              })}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </Input>
          <Input $inputError={!!errors.password}>
            <label htmlFor="password">Hasło</label>
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Hasło"
              data-testid="password"
              {...register("password", {
                required: { value: true, message: "Pole jest wymagane" },
              })}
            />
            <Icon
              data-testid="show-password"
              name={showPassword ? "eye" : "eyeSlash"}
              onClick={() => setShowPassword(!showPassword)}
            />
            {errors.password && (
              <ErrorMessage>{errors.password.message}</ErrorMessage>
            )}
          </Input>
          <Button
            type="submit"
            variant="secondary"
            title="Zaloguj się"
            icon="arrowRight"
            iconGap={50}
            data-testid="submit"
          />
        </form>
      </FormWrapper>
      <Footer />
    </Wrapper>
  );
};

export default LoginPage;
