const routeRoutes = {
  login: "/login",

  subjects: "/",
  eLearning: "/e-learning",
  coursePre: `/courses/:id`,
  course: "/e-learning/:courseId/:topicId?",
  schedule: "/harmonogram",
  team: "/kadra",
  certificates: "/certyfikaty",
};

export default routeRoutes;
