import styled from "styled-components";
import { device } from "theme/breakpoints";

import { Button } from "components/Button";

export const StyledSidebar = styled.aside<{ $agendaVisable: boolean }>`
  padding: 16px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border: 1px solid #e0e0e0;
  border-left: 2px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 3px;
  @media ${device.mobile} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: ${({ $agendaVisable }) =>
      $agendaVisable ? "translate(0, 0)" : "translate(100%, 0)"};
    transition: transform 0.25s;
    overflow: scroll;
    padding: 40px 0 55px;
    z-index: 10;

    .hide-agenda-btn {
      display: block;
      margin: 0 15px 0 auto;
    }
  }
`;

export const ShowAgendaButton = styled(Button)`
  margin: 0px 0px 0px auto;
`;
