import routeRoutes from "components/Routes/routes";

type MenuItem = {
  name: string;
  link: string;
};

export const menuItems: MenuItem[] = [
  {
    name: "Przedmioty",
    link: routeRoutes.subjects,
  },
  {
    name: "E-learning",
    link: routeRoutes.eLearning,
  },
  {
    name: "Harmonogram",
    link: routeRoutes.schedule,
  },
  {
    name: "Certifikaty",
    link: routeRoutes.certificates,
  },
  {
    name: "Kadra",
    link: routeRoutes.team,
  },
];
