import { styled } from "styled-components";
import { getStyleBasedOnTheme } from "theme/utils";

export const Container = styled.div``;

export const Text = styled.div`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-bottom: 6px;
`;

export const Percentage = styled.div`
  font-size: 11px;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 5px;
  background-color: ${({ theme }) =>
    getStyleBasedOnTheme(theme, {
      base: theme.colors.border,
      contrast: theme.colors.gray,
    })};
  position: relative;
  overflow: hidden;
`;

export const Progress = styled.div<{ progress: number }>`
  width: ${({ progress }) => progress}%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  top: 0;
  left: 0;
`;
