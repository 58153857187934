import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { format, isBefore } from "date-fns";
import { ExamGradeType } from "types/common.types";

import React, { Fragment, useContext, useEffect, useMemo } from "react";

import Loader from "components/Loader";

import { DATE_FORMAT } from "../../../consts/dates";
import {
  CenteredContainer,
  Content,
  ContentColumn,
  Divider,
  FrequencyCount,
  FrequencyList,
  FrequencyListItem,
  FrequencyTitle,
  Grade,
  GradeColor,
  GradeName,
  GradePercentage,
  Grid,
  InfoBar,
  NormalText,
} from "./styles";

type Props = {
  subject: API.GroupSubject;
};

const checkDate = (date: string) => isBefore(new Date(date), new Date());

const checkScale = (item: API.Exam): boolean => {
  const gradeScale = item.grade_scale;
  if (!gradeScale) {
    return false;
  }

  const { scale } = gradeScale;
  const examPass = scale.length === 2 ? "zal" : "3";

  const currentScale = scale.find((item) => item.name === examPass);
  return currentScale
    ? item.results?.[0]?.result >= currentScale.grade_value
    : false;
};
const checkGrade = (item: API.Exam): boolean => {
  if (item.type === ExamGradeType.ManualGrades) {
    return item.results?.[0]?.result > 2;
  }
  if (item.type === ExamGradeType.ManualPass) {
    return String(item.results?.[0]?.result) === "zal";
  }
  return false;
};
const LoadingState: React.FC = () => (
  <CenteredContainer>
    <Loader />
  </CenteredContainer>
);

const EmptyState: React.FC<{ message: string }> = ({ message }) => (
  <CenteredContainer>{message}</CenteredContainer>
);

const AttendanceList: React.FC<{ data: API.Attendance[]; title: string }> = ({
  data,
  title,
}) => (
  <div>
    <FrequencyTitle>{title}:</FrequencyTitle>
    <FrequencyList>
      {data.map(
        (item) =>
          checkDate(item.date_to) && (
            <FrequencyListItem>
              {format(new Date(item.date_to), DATE_FORMAT)}
            </FrequencyListItem>
          )
      )}
    </FrequencyList>
  </div>
);

const CollapseContent: React.FC<Props> = ({ subject }) => {
  const { attendances, fetchAttendances, fetchExams, exams } =
    useContext(EscolaLMSContext);

  useEffect(() => {
    fetchExams({ group_id: subject.group_id, per_page: 999 }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAttendances(subject.group_id); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentSubjectAttendance = useMemo(() => {
    return attendances?.byId?.[subject.group_id]?.value;
  }, [attendances, subject.group_id]);

  const { absent, present, present_not_exercising, excused_absence } = useMemo(
    () =>
      (currentSubjectAttendance ?? []).reduce<
        Record<API.AttendanceStatus, API.Attendance[]>
      >(
        (acc, attendance) => {
          switch (attendance.attendances?.[0]?.value) {
            case API.AttendanceStatus.ABSENT:
            case null:
              acc[API.AttendanceStatus.ABSENT].push(attendance);
              break;
            case API.AttendanceStatus.PRESENT:
              acc[API.AttendanceStatus.PRESENT].push(attendance);
              break;
            case API.AttendanceStatus.EXCUSED_ABSENCE:
              acc[API.AttendanceStatus.EXCUSED_ABSENCE].push(attendance);
              break;
            case API.AttendanceStatus.PRESENT_NOT_EXERCISING:
              acc[API.AttendanceStatus.PRESENT_NOT_EXERCISING].push(attendance);
              break;
          }

          return acc;
        },
        {
          [API.AttendanceStatus.ABSENT]: [],
          [API.AttendanceStatus.EXCUSED_ABSENCE]: [],
          [API.AttendanceStatus.PRESENT]: [],
          [API.AttendanceStatus.PRESENT_NOT_EXERCISING]: [],
        }
      ),
    [currentSubjectAttendance]
  );

  return (
    <Content data-testid="collapse-content">
      <ContentColumn>
        <InfoBar>Uzyskane oceny</InfoBar>
        {exams.byId?.[subject.group_id]?.loading ? (
          <LoadingState />
        ) : exams.byId?.[subject.group_id]?.value?.length === 0 ? (
          <EmptyState message="Brak ocen cząstkowych" />
        ) : (
          <>
            <Grade>
              {exams.byId?.[subject.group_id]?.value?.map((item) => (
                <Fragment key={item.id}>
                  <div>{format(new Date(item.passed_at), DATE_FORMAT)}</div>
                  <GradeName>{item.title}</GradeName>
                  {item.type !== ExamGradeType.Manual && (
                    <GradeColor $passed={checkGrade(item)}>
                      {item.results?.[0]?.result}
                    </GradeColor>
                  )}
                  {item.type === ExamGradeType.Manual && (
                    <GradePercentage $passed={checkScale(item)}>
                      {item.results?.[0]?.result}%
                    </GradePercentage>
                  )}
                </Fragment>
              ))}
            </Grade>
          </>
        )}
        {subject?.final_grades?.length > 0 && (
          <NormalText>Oceny końcowe:</NormalText>
        )}
        <Grade>
          {subject.final_grades.map((item) => (
            <Fragment key={item.id}>
              <div>{format(new Date(item.grade_date), DATE_FORMAT)}</div>
              <GradeName>{item.grade_term?.name}</GradeName>
              <GradePercentage>{item.grade_name}</GradePercentage>
            </Fragment>
          ))}
        </Grade>
      </ContentColumn>

      <ContentColumn>
        <InfoBar>Frekwencja</InfoBar>
        {attendances.byId?.[subject.group_id]?.loading ? (
          <LoadingState />
        ) : attendances?.byId?.[subject.group_id]?.value?.length === 0 ? (
          <EmptyState message="Brak planu" />
        ) : (
          <Grid>
            <FrequencyTitle>Obecności:</FrequencyTitle>
            <FrequencyCount>{present.length}</FrequencyCount>
            <FrequencyTitle>Obecności (nie ćwiczące):</FrequencyTitle>
            <FrequencyCount>{present_not_exercising.length}</FrequencyCount>
            <FrequencyTitle>Nieobecności:</FrequencyTitle>
            <FrequencyCount>
              {absent.filter((item) => checkDate(item.date_to)).length}
            </FrequencyCount>
            <FrequencyTitle>Nieobecności usprawiedliwione:</FrequencyTitle>
            <FrequencyCount>{excused_absence.length}</FrequencyCount>
            <Divider />
            <FrequencyTitle>Łączna liczba odbytych zajęć:</FrequencyTitle>
            <FrequencyCount>
              {`
                  ${
                    currentSubjectAttendance?.filter((item) =>
                      checkDate(item.date_to)
                    ).length
                  } / ${currentSubjectAttendance?.length}`}
            </FrequencyCount>
          </Grid>
        )}
      </ContentColumn>

      <ContentColumn>
        <InfoBar>Obecności</InfoBar>
        {attendances.byId?.[subject.group_id]?.loading ? (
          <LoadingState />
        ) : attendances?.byId?.[subject.group_id]?.value?.length === 0 ? (
          <EmptyState message="Brak danych" />
        ) : (
          <Grid $equal>
            <AttendanceList data={present} title="Obecności" />
            <AttendanceList
              data={present_not_exercising}
              title="Obecności (nie ćwiczące)"
            />
          </Grid>
        )}
      </ContentColumn>

      <ContentColumn>
        <InfoBar>Nieobecności</InfoBar>
        {attendances.byId?.[subject.group_id]?.loading ? (
          <LoadingState />
        ) : attendances?.byId?.[subject.group_id]?.value?.length === 0 ? (
          <EmptyState message="Brak danych" />
        ) : (
          <Grid $equal>
            <AttendanceList data={absent} title="Nieobecności" />
            <AttendanceList
              data={excused_absence}
              title="Nieobecności usprawiedliwione"
            />
          </Grid>
        )}
      </ContentColumn>
    </Content>
  );
};

export default CollapseContent;
