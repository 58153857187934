import type { DefaultTheme } from "styled-components";

import sharedThemeValues from "./shared";

const contrastTheme: DefaultTheme = {
  name: "contrast" as const,
  fontSize: "normal" as const,
  colors: {
    primary: "#ffff00",
    secondary: "#ffff00",
    black: "#212121",
    gray: "#363636",
    white: "#FFFFFF",
    primaryBackground: "#000000",
    secondaryBackground: "#242424",
    primaryButton: "#ffff00",
    border: "#ffff00",
    green: "#3EB200",
    red: "#9B3939",
    textPrimary: "#ffff00",
    textSecondary: "#ffff00",
    textAccent: "#000000",
    error: "#FF4545",
  },
  shadows: {
    primary: "0px 5px 25px rgba(0, 0, 0, 0.15)",
  },
  ...sharedThemeValues,
};

export default contrastTheme;
