import styled from "styled-components";
import { getStyleBasedOnTheme } from "theme/utils";

type AvatarImageProps = {
  size: "sm" | "md";
};

export const AvatarContainer = styled.div<AvatarImageProps>`
  width: ${({ size }) => (size === "sm" ? "35px" : "76px")};
  height: ${({ size }) => (size === "sm" ? "35px" : "76px")};
  border-radius: 50%;
  background-color: ${({ theme }) =>
    getStyleBasedOnTheme(theme, {
      base: theme.colors.secondary,
      contrast: theme.colors.gray,
    })};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme, size }) =>
    size === "sm" ? theme.fontSizes.medium : theme.fontSizes.xlarge};
  color: ${({ theme }) => theme.colors.textSecondary};
  & img {
    width: ${({ size }) => (size === "sm" ? "35px" : "76px")};
    height: ${({ size }) => (size === "sm" ? "35px" : "76px")};
    border-radius: 50%;
  }
`;
