import { EscolaLMSContextProvider } from "@escolalms/sdk/lib/react/context";
import ReactDOM from "react-dom/client";
import ThemeWrapper from "theme/ThemeWrapper";
import WebFont from "webfontloader";

import React from "react";

import { SchoolContextProvider } from "components/SchoolContext";

import App from "./App";
import "./sentry";

WebFont.load({
  google: {
    families: ["Lato:ital,wght@0,400;0,700;0,400;1,700;1"],
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const API_URL =
  process.env.REACT_APP_API_URL || window.REACT_APP_API_URL || "";

root.render(
  <React.StrictMode>
    <EscolaLMSContextProvider apiUrl={API_URL} initialFetch={false}>
      <SchoolContextProvider>
        <ThemeWrapper>
          <App />
        </ThemeWrapper>
      </SchoolContextProvider>
    </EscolaLMSContextProvider>
  </React.StrictMode>
);
