import styled from "styled-components";
import { device } from "theme/breakpoints";

export const CalendarContainer = styled.div`
  .rbc-calendar {
    height: 80vh;
  }

  .rbc-month-view,
  .rbc-time-view {
    border-radius: 4px;
    overflow: hidden;
  }

  .rbc-header {
    color: ${({ theme }) => theme.colors.textAccent};
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    background-color: ${({ theme }) => theme.colors.primary} !important;
    padding-bottom: 2px;
  }

  .rbc-today {
    background-color: ${({ theme }) => theme.colors.primaryBackground};
  }

  .rbc-now {
    color: ${({ theme }) => theme.colors.secondary};
  }

  .rbc-event {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.textAccent};
    font-size: ${({ theme }) => theme.fontSizes.large};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.primaryBackground} !important;
    cursor: pointer;
    transition: 0.3s;
    &-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  .rbc-agenda-event-cell {
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  .rbc-selected {
    background-color: ${({ theme }) => theme.colors.secondary} !important;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary} !important;
    }
  }
  .rbc-toolbar {
    gap: 10px;
    @media ${device.mobile} {
      display: flex;
      flex-direction: column;
      .rbc-btn-group:last-of-type {
        & button:nth-of-type(1),
        & button:nth-of-type(2) {
          display: none;
        }
        & button:nth-of-type(3) {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
    padding: 10px 0px;
  }
  .rbc-toolbar-label {
    font-size: ${({ theme }) => theme.fontSizes.xlarge};
  }
  .rbc-btn-group button {
    font-size: ${({ theme }) => theme.fontSizes.large};
    border-color: ${({ theme }) => theme.colors.border}!important;
    font-family: ${({ theme }) => theme.fonts.primary};
    background-color: ${({ theme }) =>
      theme.colors.primaryBackground} !important;
    color: ${({ theme }) => theme.colors.textPrimary};
    transition: 0.3s;
    cursor: pointer;

    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.colors.secondary}!important;
      color: ${({ theme }) => theme.colors.white}!important;
    }
  }
  .rbc-current-time-indicator {
    background-color: ${({ theme }) => theme.colors.gray};
  }
`;
